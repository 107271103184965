import React, { ReactElement } from 'react';
import { kebabCase } from 'lodash';
import { Box } from '@mui/material';
import { ODButton, ODDialog } from '@OptimalDynamics/core-ai-common-ui';
import ODTransition from './ODTransition';

interface ConfirmContainerProps {
  open: boolean;
  title: string;
  onAction: (_b: boolean) => void;
  source?: string;
  confirmTxt?: string;
  confirmIcon?: ReactElement | false;
  titleRight?: ReactElement;
  reverseButtons?: boolean;
  disableConfirm?: boolean;
  children: ReactElement | ReactElement[] | null;
}

export const ConfirmContainer = ({
  open,
  title,
  onAction,
  source = '',
  children,
  confirmTxt = 'Confirm',
  confirmIcon = undefined,
  titleRight = <></>,
  reverseButtons = false,
  disableConfirm = false,
}: ConfirmContainerProps) => (
  <div
    data-testid={`title:${title}--confirmTxt:${confirmTxt}--container`}
  >
    <ODDialog
      open={open}
      TransitionComponent={ODTransition}
      keepMounted
      onClose={() => onAction(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      title={title}
      titleRight={titleRight}
      sx={{ '&.MuiDialog-root': { zIndex: '1500 !important' } }}
    >
      {children}
      <Box style={{ marginTop: '32px', display: 'flex', columnGap: '16px', flexDirection: reverseButtons ? 'row-reverse' : 'row' }}>
        <ODButton
          sx={{ width: 'calc(50% - 8px)' }}
          id={`${title}-${source}-confirm-secondary-action-button`}
          data-testid={`title:${title}-${source}-confirm-secondary-action-button`}
          onClick={() => onAction(false)}
        >
          Cancel
        </ODButton>
        <ODButton
          sx={{ width: 'calc(50% - 8px)', justifyContent: 'none' }}
          variant="blue"
          id={`${kebabCase(title)}-${source}-confirm-primary-action-button`}
          data-testid={`title:${title}-${source}-confirm-primary-action-button`}
          startIcon={confirmIcon}
          onClick={() => { if (!disableConfirm) onAction(true); }}
          disabled={disableConfirm}
        >
          {confirmTxt}
        </ODButton>
      </Box>
    </ODDialog>
  </div>
);

export default ConfirmContainer;
