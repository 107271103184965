import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import PeopleSharp from '@mui/icons-material/PeopleSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import ShuffleSharp from '@mui/icons-material/ShuffleSharp';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import CancelSharp from '@mui/icons-material/CancelSharp';
import ConnectWithoutContactSharp from '@mui/icons-material/ConnectWithoutContactSharp';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LOADED, DRIVER, LOAD, BROKERAGE, SOURCE } from './constants';

export const IconWrapper = ({ bgColor, children }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '32px',
      width: '32px',
      borderRadius: '16px',
      boxSizing: 'border-box',
      backgroundColor: bgColor || 'inherit',
      '> p': {
        height: '18px',
        width: '18px',
        borderRadius: '9px',
        textAlign: 'center',
        lineHeight: '17px',
      },
    }}
  >
    {children}
  </Box>
);

export const DriverIdentifier = ({ driver }) => {
  const { addDriverNameUi } = useFlags();

  if (!driver) return null;

  const currentDriver = driver.driver || driver;
  const { driver_last_name: lastName, driver_first_name: firstName, driver_id: driverId } = currentDriver;

  if (!addDriverNameUi || (!lastName && !firstName)) return driverId;

  return `${lastName}, ${firstName}`;
};

export const dynamicSourceBrokerageDescription = (selectedObj, targetObj, selectedObjType, viewType) => {
  if (!selectedObj || !targetObj) return null;

  if (!selectedObjType) {
    return dynamicSourceBrokerageDescription([targetObj], { driver: selectedObj }, LOAD);
  }

  const filteredSelectedObj = selectedObj.length > 1
    ? selectedObj.filter(
      (opt, index) => (!opt.has_source && opt.movement_type === LOADED || (index === selectedObj.findIndex((o) => o.has_source))) && opt.active
    ) : selectedObj;
  
  const driverHasSourceRequest = selectedObjType === LOAD ? targetObj?.has_source_request : filteredSelectedObj?.[0]?.has_source_request;

  return (
    <>
      {viewType !== SOURCE && (
        <Box
          sx={{
            minHeight: '20px',
            backgroundColor: 'colors.lightBlue1',
            display: 'flex',
            alignItems: filteredSelectedObj.length > 1 ? 'flex-start' : 'center',
            borderRadius: '4px',
            padding: '10px 16px',
            fontSize: '14px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px', mr: 1 }}>
            {driverHasSourceRequest ? (
              <IconWrapper bgColor="colors.neutral6">
                <ConnectWithoutContactSharp sx={{ color: 'text.inverse' }} />
              </IconWrapper>
            ) : (
              <IconWrapper>
                {targetObj?.driver?.is_sleeper ? <PeopleSharp /> : <PersonSharp />}
              </IconWrapper>
            )}
            <DriverIdentifier driver={selectedObjType === LOAD ? targetObj : filteredSelectedObj?.[0]} />
            <ArrowForward sx={{ mx: 1 }} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              ...(filteredSelectedObj.length > 1
                ? { flexDirection: 'column', rowGap: '10px', alignItems: 'flex_start' }
                : { flexDirection: 'row', columnGap: '8px', alignItems: 'center' }),
            }}
          >
            {filteredSelectedObj.length > 1 ? (
              filteredSelectedObj.map((obj, index) => (
                <Box key={`confirm-${index}`} sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
                  <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                      fontSize: 12,
                      fontWeight: 600,
                      backgroundColor: 'colors.lightBlue1',
                      color: 'text.primary',
                      border: '1px solid',
                      borderColor: 'level3',
                      borderRadius: '50%',
                    }}
                  >
                    {index + 1}
                  </Avatar>
                  {obj?.internal_source === BROKERAGE ? (
                    <IconWrapper bgColor="warning.main">
                      <ShuffleSharp />
                    </IconWrapper>
                  ) : (selectedObjType === LOAD && obj?.has_source) ? (
                    <IconWrapper bgColor="colors.neutral6">
                      <ConnectWithoutContactSharp sx={{ color: 'text.inverse' }} />
                    </IconWrapper>
                  ) : (
                    <IconWrapper bgColor="optimalblue.main">
                      <CheckCircleSharp sx={{ color: 'text.inverse' }} />
                    </IconWrapper>
                  )}
                  {selectedObjType === LOAD ? (obj?.has_source ? 'SEND REQUEST' : obj?.load_id) : <DriverIdentifier driver={obj} />}
                </Box>
              ))
            ) : (
              <>
                {filteredSelectedObj?.[0]?.internal_source === BROKERAGE ? (
                  <IconWrapper bgColor="warning.main">
                    <ShuffleSharp />
                  </IconWrapper>
                ) : (selectedObjType === LOAD && filteredSelectedObj?.[0]?.has_source) ? (
                  <IconWrapper bgColor="colors.neutral6">
                    <ConnectWithoutContactSharp sx={{ color: 'text.inverse' }} />
                  </IconWrapper>
                ) : (
                  <IconWrapper bgColor="optimalblue.main">
                    <CheckCircleSharp sx={{ color: 'text.inverse' }} />
                  </IconWrapper>
                )}
                <Typography noWrap maxWidth={112}>
                  {selectedObjType === DRIVER ? targetObj?.load_id : (filteredSelectedObj?.[0]?.has_source ? 'SEND REQUEST' : filteredSelectedObj?.[0]?.load_id)}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}
      {driverHasSourceRequest && (
        <Box
          sx={{
            minHeight: '20px',
            backgroundColor: 'colors.alertBarRed',
            display: 'flex',
            alignItems: 'flex-start',
            borderRadius: '4px',
            padding: '10px 16px',
            fontSize: '14px'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px', mr: 1 }}>
            <IconWrapper>
              {targetObj?.driver?.is_sleeper ? <PeopleSharp /> : <PersonSharp />}
            </IconWrapper>
            <DriverIdentifier driver={selectedObjType === LOAD ? targetObj : filteredSelectedObj?.[0]} />
            <ArrowForward sx={{ mx: 1 }} />
            <IconWrapper bgColor="colors.semanticRed">
              <CancelSharp sx={{ color: 'text.inverse' }} />
            </IconWrapper>
            REMOVE REQUEST
          </Box>
        </Box>
      )}
    </>
  );
};
