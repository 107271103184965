import React, { useEffect, useRef, useState } from 'react';
import { ODSlideOut, ODToggle } from '@OptimalDynamics/core-ai-common-ui';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { partition } from 'lodash';
import FilterAccordion from '../../../common/FilterAccordion';
import AutoCompleteBox from '../../../common/AutoCompleteBox';
import SelectedOptions from '../../../common/SelectedOptions';
import { UpdateUserSettings } from '../../../store/actions';
import LocationSearch from '../../../common/LocationSearch';
import draftStore from '../../../utils/draftStore';
import { TMS_REGION, CITY_STATE, STATE } from '../helpers/constants';

const DispatchingByLoadsFiltersUpdate = (props) => {
  const { open, onClose, setFilters, filters = null, setAppliedFilterCount, setSelectedRows } = props;
  const [selectedShipperIds, setSelectedShipperIds] = useState([]);
  const [selectedShipperNames, setSelectedShipperNames] = useState([]);
  const [selectedLoadIds, setSelectedLoadIds] = useState([]);
  const [selectedPickUpLocations, setSelectedPickUpLocations] = useState([]);
  const [selectedDropOffLocations, setSelectedDropOffLocations] = useState([]);
  const [selectedPickupRegionCodes, setSelectedPickupRegionCodes] = useState([]);
  const [selectedDropoffRegionCodes, setSelectedDropoffRegionCodes] = useState([]);
  const [selectedLocationType, setSelectedLocationType] = useState(CITY_STATE);
  const {
    loadFilterOptions: { shipper_ids: shipperIds, region_codes: regionCodes, shipper_names: shipperNames },
    loadOptions
  } = useSelector((state) => state.dispatchingReducer);
  const userSettings = useSelector((state) => state.userSettings);
  const dispatch = useDispatch();
  const { useRegionCodesDispatching, useShipperNameFilter } = useFlags();

  // applies updated filters
  useEffect(() => {
    if (!userSettings?.userSettingsDataLoaded || !open) return;
    if (
      !selectedShipperIds.length
      && selectedShipperNames.length
      && selectedPickupRegionCodes.length
      && selectedDropoffRegionCodes.length
      && selectedLoadIds.length
      && selectedPickUpLocations.length
      && selectedDropOffLocations.length
    ) return;
    updateAppliedFilters();
  }, [
    selectedShipperIds,
    selectedShipperNames,
    selectedPickupRegionCodes,
    selectedDropoffRegionCodes,
    selectedLocationType,
    selectedLoadIds,
    selectedPickUpLocations,
    selectedDropOffLocations
  ]);

  // waits for disptaching filters prop to have a non-null value, then sets the filter values just once
  const firstDispatchingByDriversFiltersUpdate = useRef(true);  
  useEffect(() => {
    if (firstDispatchingByDriversFiltersUpdate.current && !!filters) {
      firstDispatchingByDriversFiltersUpdate.current = false;
      if (!!filters) {
        setSelectedShipperIds(getShipperIdFilterValues());
        setSelectedShipperNames(getShipperNameFilterValues());
        setSelectedLoadIds(getLoadIdFilterValues());
        setSelectedPickUpLocations(getPickUpLocationFilterValues());
        setSelectedDropOffLocations(getDropOffLocationFilterValues());
        setSelectedLocationType(getLocationTypeFilterValue());
        setSelectedPickupRegionCodes(getPickupRegionCodesFilterValues());
        setSelectedDropoffRegionCodes(getDropoffRegionCodesFilterValues());
      }
    }
  }, [filters]);

  const updateAppliedFilters = () => {
    const updatedStoredFilters = {};
    const updatedSessionFilters = {};
    let filterCount = 0;
    updatedStoredFilters.location_type = selectedLocationType;
    if (!!selectedLoadIds.length) {
      updatedSessionFilters.load_id = selectedLoadIds.map((type) => type.value);
      filterCount += 1;
    }
    if (!!selectedShipperIds.length) {
      updatedStoredFilters.shipper_id = selectedShipperIds.map((type) => type.value);
      filterCount += 1;
    }
    if (!!selectedShipperNames.length) {
      updatedStoredFilters.shipper_name = selectedShipperNames.map((type) => type.value);
      filterCount += 1;
    }
    if (useRegionCodesDispatching && (selectedLocationType === TMS_REGION)) {
      if (!!selectedPickupRegionCodes.length || !!selectedDropoffRegionCodes.length) filterCount += 1;

      if (!!selectedPickupRegionCodes.length) {
        updatedStoredFilters.pickup_region_codes = selectedPickupRegionCodes.map((type) => type.value);
      }
      if (!!selectedDropoffRegionCodes.length) {
        updatedStoredFilters.dropoff_region_codes = selectedDropoffRegionCodes.map((type) => type.value);
      }
    } else {
      if (!!selectedPickUpLocations.length || !!selectedDropOffLocations.length) filterCount += 1;

      if (!!selectedPickUpLocations.length) {
        const [pickupStates, pickupCities] = partition(selectedPickUpLocations, (loc) => Object.keys(loc)[0] === STATE);
        if (!!pickupStates.length) updatedStoredFilters.pickup_state = pickupStates.map((loc) => loc[STATE]);
        if (!!pickupCities.length) updatedStoredFilters.pickup_locations = pickupCities.map((loc) => loc[CITY_STATE]);
      }
      if (!!selectedDropOffLocations.length) {
        const [dropoffStates, dropoffCities] = partition(selectedDropOffLocations, (loc) => Object.keys(loc)[0] === STATE);
        if (!!dropoffStates.length) updatedStoredFilters.dropoff_state = dropoffStates.map((loc) => loc[STATE]);
        if (!!dropoffCities.length) updatedStoredFilters.dropoff_locations = dropoffCities.map((loc) => loc[CITY_STATE]);
      }
    }
    setSelectedRows([]);
    setFilters({ ...updatedStoredFilters, ...updatedSessionFilters });
    draftStore.setSession('dispatchingLoadFilters', updatedSessionFilters);
    dispatch(UpdateUserSettings({ ...userSettings, loadDispatchingFilters: updatedStoredFilters }));
    setAppliedFilterCount(filterCount);
  };

  const getLocationTypeFilterValue = () => filters?.location_type || selectedLocationType;

  const getShipperIdFilterValues = () => filters?.shipper_id?.map((id) => ({ value: id })) || [];

  const getShipperNameFilterValues = () => filters?.shipper_name?.map((name) => ({ value: name })) || [];
  
  const getPickupRegionCodesFilterValues = () => filters?.pickup_region_codes?.map((id) => ({ value: id })) || [];

  const getDropoffRegionCodesFilterValues = () => filters?.dropoff_region_codes?.map((id) => ({ value: id })) || [];

  const getLocationFilterValues = () => filters?.locations?.map((loc) => {
    if (/^[A-Z]{2}$/.test(loc)) return { state: loc };
    return { city_state: loc };
  }) || [];

  const getLoadIdFilterValues = () => filters?.load_id?.map((id) => ({ value: id })) || [];

  const getPickUpLocationFilterValues = () => {
    const cities = filters?.pickup_locations?.map((loc) => ({ city_state: loc })) || [];
    const states = filters?.pickup_state?.map((loc) => ({ state: loc })) || [];
    return [...cities, ...states];
  };

  const getDropOffLocationFilterValues = () => {
    const cities = filters?.dropoff_locations?.map((loc) => ({ city_state: loc })) || [];
    const states = filters?.dropoff_state?.map((loc) => ({ state: loc })) || [];
    return [...cities, ...states];
  };

  const onReset = () => {
    setFilters({});
    setSelectedShipperIds([]);
    setSelectedShipperNames([]);
    setSelectedLocationType(CITY_STATE);
    setSelectedPickupRegionCodes([]);
    setSelectedDropoffRegionCodes([]);
    setSelectedLoadIds([]);
    setSelectedPickUpLocations([]);
    setSelectedDropOffLocations([]);
    dispatch(UpdateUserSettings({ ...userSettings, loadDispatchingFilters: {} }));
    draftStore.removeSession('dispatchingLoadFilters');
    setAppliedFilterCount(0);
    setSelectedRows([]);
  };

  const removeSelection = (selectedValues, valuesSetter, key, value) => {
    const updatedArray = selectedValues.filter((selectedValue) => selectedValue[key] !== value[key]);
    valuesSetter(updatedArray);
  };

  return (
    <ODSlideOut
      title="Filters"
      sx={{ zIndex: 1300 }}
      open={open}
      onClose={onClose}
      actionText="Reset"
      onAction={onReset}
    >
      <Box sx={{ width: '100%' }}>
        <FilterAccordion
          title="Load ID"
          defaultExpanded={!!getLoadIdFilterValues().length}
          sx={{ width: '100%', '& .MuiAccordionDetails-root': { mb: 2 }, '& .MuiAutocomplete-root': { mb: 2 } }}
        >
          <AutoCompleteBox
            title="Select Load ID"
            handleChange={setSelectedLoadIds}
            value={getLoadIdFilterValues()}
            autoCompleteList={loadOptions}
            style={{ width: '100%' }}
            placeholder="Select Options"
            onClear={() => setSelectedLoadIds([])}
            optionLabel="value"
            startAdornment={false}
          />
          <SelectedOptions
            keyBase="load-ids"
            value={getLoadIdFilterValues()}
            label="Load ID"
            removeOptionCB={(val) => removeSelection(getLoadIdFilterValues(), setSelectedLoadIds, 'value', val)}
          />
        </FilterAccordion>
      </Box>

      <Box sx={{ width: '100%' }}>
        <FilterAccordion
          title="Shipper ID"
          sx={{ width: '100%', '& .MuiAccordionDetails-root': { mb: 2 }, '& .MuiAutocomplete-root': { mb: 2 } }}
          defaultExpanded={!!getShipperIdFilterValues().length}
        >
          <AutoCompleteBox
            title="Select Shipper ID"
            value={getShipperIdFilterValues()}
            handleChange={setSelectedShipperIds}
            autoCompleteList={shipperIds}
            placeholder="Select Options"
            onClear={() => setSelectedShipperIds([])}
            optionLabel="value"
            startAdornment={false}
          />
          <SelectedOptions
            keyBase="shipperIds"
            value={getShipperIdFilterValues()}
            label="Shipper Id"
            removeOptionCB={(val) => removeSelection(getShipperIdFilterValues(), setSelectedShipperIds, 'value', val)}
          />
        </FilterAccordion>
      </Box>

      {useShipperNameFilter && (
        <Box sx={{ width: '100%' }}>
          <FilterAccordion
            title="Shipper Name"
            sx={{ width: '100%', '& .MuiAccordionDetails-root': { mb: 2 }, '& .MuiAutocomplete-root': { mb: 2 } }}
            defaultExpanded={!!getShipperNameFilterValues().length}
          >
            <AutoCompleteBox
              title="Select Shipper Name"
              value={getShipperNameFilterValues()}
              handleChange={setSelectedShipperNames}
              autoCompleteList={shipperNames}
              placeholder="Select Options"
              onClear={() => setSelectedShipperNames([])}
              optionLabel="value"
              startAdornment={false}
            />
            <SelectedOptions
              keyBase="shipperNames"
              value={getShipperNameFilterValues()}
              label="Shipper Name"
              removeOptionCB={(val) => removeSelection(getShipperNameFilterValues(), setSelectedShipperNames, 'value', val)}
            />
          </FilterAccordion>
        </Box>
      )}

      <Box sx={{ width: '100%' }}>
        <FilterAccordion
          title="Pick Up or Drop Off Locations"
          sx={{ '.MuiAccordionDetails-root': { display: 'flex', flexDirection: 'column', rowGap: '16px' } }}
          defaultExpanded={
            !!getPickUpLocationFilterValues().length
            || !!getDropOffLocationFilterValues().length
            || !!getLocationFilterValues().length
            || !!getPickupRegionCodesFilterValues().length
          }
        >
          {useRegionCodesDispatching && (
            <>
              <div>
                <ODToggle
                  prompt="Select Location Type"
                  selected={getLocationTypeFilterValue()}
                  onChange={(_e, v) => setSelectedLocationType(v)}
                  options={[
                    { value: TMS_REGION, label: 'TMS Region' },
                    { value: CITY_STATE, label: 'City / State' }
                  ]}
                />
              </div>
              {selectedLocationType === TMS_REGION && (
                <>
                  <AutoCompleteBox
                    title="Select Pick Up TMS Region"
                    value={getPickupRegionCodesFilterValues()}
                    handleChange={setSelectedPickupRegionCodes}
                    autoCompleteList={regionCodes}
                    placeholder="Select Regions"
                    onClear={() => setSelectedPickupRegionCodes([])}
                    optionLabel="value"
                    startAdornment={false}
                  />
                  <SelectedOptions
                    keyBase="dispatch-state"
                    value={getPickupRegionCodesFilterValues()}
                    itemKey="value"
                    label="Pick Up Region"
                    removeOptionCB={(val) => removeSelection(getPickupRegionCodesFilterValues(), setSelectedPickupRegionCodes, 'value', val)}
                  />
                  <AutoCompleteBox
                    title="Select Drop Off TMS Region"
                    value={getDropoffRegionCodesFilterValues()}
                    handleChange={setSelectedDropoffRegionCodes}
                    autoCompleteList={regionCodes}
                    placeholder="Select Regions"
                    onClear={() => setSelectedDropoffRegionCodes([])}
                    optionLabel="value"
                    startAdornment={false}
                  />
                  <SelectedOptions
                    keyBase="dispatch-state"
                    value={getDropoffRegionCodesFilterValues()}
                    itemKey="value"
                    label="Drop Off Region"
                    removeOptionCB={(val) => removeSelection(getDropoffRegionCodesFilterValues(), setSelectedDropoffRegionCodes, 'value', val)}
                  />
                </>
              )}
            </>
          )}
          {selectedLocationType === CITY_STATE && (
            <>
              <LocationSearch
                label="Select Pick Up City or State"
                inputSelectorId="dispatch-pick-up-location-autocomplete-box"
                selectedLocations={getPickUpLocationFilterValues()}
                setSelectedLocations={setSelectedPickUpLocations}
              />
              <SelectedOptions
                keyBase="dispatch-state"
                value={getPickUpLocationFilterValues().filter((loc) => Object.keys(loc)[0] === STATE) || []}
                itemKey={STATE}
                label="State"
                removeOptionCB={(val) => removeSelection(getPickUpLocationFilterValues(), setSelectedPickUpLocations, STATE, val)}
              />
              <SelectedOptions
                keyBase="dispatch-city"
                value={getPickUpLocationFilterValues().filter((loc) => Object.keys(loc)[0] === CITY_STATE) || []}
                itemKey={CITY_STATE}
                label={['City', 'Cities']}
                removeOptionCB={(val) => removeSelection(getPickUpLocationFilterValues(), setSelectedPickUpLocations, CITY_STATE, val)}
              />
              <LocationSearch
                label="Select Drop Off City or State"
                inputSelectorId="dispatch-drop-off-location-autocomplete-box"
                selectedLocations={getDropOffLocationFilterValues()}
                setSelectedLocations={setSelectedDropOffLocations}
              />
              <SelectedOptions
                keyBase="dispatch-dropoff-state"
                value={getDropOffLocationFilterValues().filter((loc) => Object.keys(loc)[0] === STATE) || []}
                itemKey={STATE}
                label="State"
                removeOptionCB={(val) => removeSelection(getDropOffLocationFilterValues(), setSelectedDropOffLocations, STATE, val)}
              />
              <SelectedOptions
                keyBase="dispatch-dropoff-city"
                value={getDropOffLocationFilterValues().filter((loc) => Object.keys(loc)[0] === CITY_STATE) || []}
                itemKey={CITY_STATE}
                label={['City', 'Cities']}
                removeOptionCB={(val) => removeSelection(getDropOffLocationFilterValues(), setSelectedDropOffLocations, CITY_STATE, val)}
              />
            </>
          )}
        </FilterAccordion>
      </Box>
    </ODSlideOut>
  );
};

export default DispatchingByLoadsFiltersUpdate;
