import React, { ChangeEvent, useState } from 'react';
import { Box, Typography, InputAdornment, InputLabel } from '@mui/material';
import AddSharp from '@mui/icons-material/AddSharp';
import RemoveSharp from '@mui/icons-material/RemoveSharp';
import { ODInput, ODAvatar } from '@OptimalDynamics/core-ai-common-ui';

export interface ODNumberInputDollarsProps {
  id: string;
  prompt: string;
  value: number | null;
  setValue: (n: number | null) => void;
  increment: number;
  errored: boolean;
  errorMessage: string;
  maximum?: number;
  minimum?: number;
  placeholder?: string;
}

const ODNumberInputDollars = ({ id, prompt, value, setValue, increment, errored, errorMessage, maximum, minimum = 0, placeholder }: ODNumberInputDollarsProps) => {
  const [inputValue, setInputValue] = useState<string>(value !== null ? `$${value.toFixed(2)}` : '');

  let minDisabled = true;
  let maxDisabled = true;
  if (value !== null) {
    minDisabled = value - increment < minimum;
    maxDisabled = maximum === undefined ? false : (value + increment > maximum);
  }

  return (
    <Box>
      <InputLabel>{prompt}</InputLabel>
      <ODInput
        id={`${id}-radius`}
        value={inputValue}
        placeholder={placeholder}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const input = event.target.value.replace('$', ''); // Remove $ for parsing
          if (/^\d*\.?\d{0,2}$/.test(input)) { // Allow numerical value with 2 decimals
            if (input === '') {
              setInputValue('');
              setValue(null);
            } else {
              setInputValue(`$${input}`);
              setValue(parseFloat(input));
            }
          }
        }}
        onBlur={() => {
          if (inputValue !== '') {
            const parsedValue = parseFloat(inputValue.replace('$', '')).toFixed(2);
            setInputValue(`$${parsedValue}`);
            setValue(parseFloat(parsedValue));
          } else {
            setInputValue('');
            setValue(null);
          }
        }}
        disableUnderline={true}
        error={errored}
        startAdornment={(
          <InputAdornment position="start">
            <ODAvatar
              variant="transparent"
              size="small"
              onClick={() => {
                if (!minDisabled) {
                  const newValue = parseFloat(((value || 0) - increment).toFixed(2));
                  setValue(newValue);
                  setInputValue(`$${newValue.toFixed(2)}`);
                }
              }}
              disabled={minDisabled}
            >
              <RemoveSharp />
            </ODAvatar>
          </InputAdornment>
        )}
        endAdornment={(
          <InputAdornment position="end">
            <ODAvatar
              variant="transparent"
              size="small"
              onClick={() => {
                if (!maxDisabled) {
                  const newValue = parseFloat(((value || 0) + increment).toFixed(2));
                  setValue(newValue);
                  setInputValue(`$${newValue.toFixed(2)}`);
                }
              }}
              disabled={maxDisabled}
            >
              <AddSharp />
            </ODAvatar>
          </InputAdornment>
        )}
        sx={{ px: 2, '.MuiFilledInput-input': { textAlign: 'center' } }}
      />
      {errored && <Typography sx={{ fontSize: '12px', color: 'alert.main' }}>{errorMessage}</Typography>}
    </Box>
  );
};

export default ODNumberInputDollars;
