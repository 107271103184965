import { batch } from 'react-redux';
import instance from '../../utils/axios_instance';
import {
  SET_DRIVER_DISPATCHING_FILTER_OPTIONS,
  SET_DRIVERS_DISPATCHING_LIST,
  SET_DRIVERS_DISPATCHING_LIST_COUNT,
  SET_DRIVER_STATUS,
  ADD_SELECTED_LOAD_ASSIGNMENT,
  REMOVE_SELECTED_LOAD_ASSIGNMENT,
  CLEAR_SELECTED_LOAD_ASSIGNMENT,
  SET_TOP_LOAD_OPTIONS,
  CLEAR_TOP_LOAD_OPTIONS,
  REMOVE_TOP_LOAD_OPTION,
  SET_OFFERED_LOAD_DETAILS,
  SET_DISPATCHING_ASSIGNMENT_LIST,
  SET_DISPATCHING_ASSIGNMENT_LIST_COUNT,
  CHECK_OR_SET_DATA_UPDATE,
  SET_DISPATCHING_ASSIGNMENT_HISTORY,
  SET_DISPATCHING_ASSIGNMENT_HISTORY_COUNT,
  SET_DISPATCHING_HISTORY_ACTIVE,
  SET_DISPATCHING_HISTORY_EXPIRATION_TIMING,
  SET_LOADS_DISPATCHING_LIST,
  SET_LOADS_DISPATCHING_LIST_COUNT,
  SET_LOADING_STATUS,
  SET_LOAD_DISPATCHING_FILTER_OPTIONS,
  SET_DISPATCHING_ENGINE_RUN_ID,
  SET_DRIVERS_LIST,
  SET_DISPATCHING_LOAD_OPTIONS,
  SET_DISPATCHING_PLANNING_HORIZON,
  POLLING_UPDATE,
  SET_DISPATCHING_DRIVER_MANAGER_FILTER_OPTIONS,
  SET_DRIVER_AVAILABILITY_DATA,
  SET_LOAD_AVAILABILITY_DATA,
  SET_KPI_CHARTS_DATA,
  SHOW_KPI_LOADER,
} from '../constants/actionTypes';
import { ShowScenarioLoader } from './loads';
import { createActionSet } from '../modules';
import { driverIDs, cityStates } from '../../utils/mocks';

export const ShowKpiLoader = (show) => ({
  type: SHOW_KPI_LOADER,
  show,
});

export const setDriverAvailabilityData = (data) => ({
  type: SET_DRIVER_AVAILABILITY_DATA,
  data,
});

export const setLoadAvailabilityData = (data) => ({
  type: SET_LOAD_AVAILABILITY_DATA,
  data,
});

export const setDriversDispatchingList = (list) => ({
  type: SET_DRIVERS_DISPATCHING_LIST,
  list,
});

export const setDriversDispatchingListCount = (count) => ({
  type: SET_DRIVERS_DISPATCHING_LIST_COUNT,
  count
});

export const setDispatchingHistoryActive = (historyActive) => ({
  type: SET_DISPATCHING_HISTORY_ACTIVE,
  historyActive
});

export const setDispatchingEngineRunId = (engineRunId) => ({
  type: SET_DISPATCHING_ENGINE_RUN_ID,
  engineRunId
});

export const setPlanningHorizon = (planningHorizon) => ({
  type: SET_DISPATCHING_PLANNING_HORIZON,
  planningHorizon
});

export const fetchDriversDispatchingList = (limit, offset, params) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  instance
    .post(`/dispatching/drivers-options/?limit=${limit}&offset=${offset}`, params)
    .then((res) => {
      batch(() => {
        dispatch(setDriversDispatchingList(res.data.results));
        dispatch(setDriversDispatchingListCount(res.data.count));
        dispatch(setDispatchingHistoryActive(res.data.show_history));
        dispatch(setDispatchingEngineRunId(res.data.engine_run_id));
        dispatch(setPlanningHorizon(res.data.planning_horizon));
      });
      Promise.resolve();
    })
    .catch((err) => console.error(err));
  dispatch(ShowScenarioLoader(false));
};

export const fetchDriversDispatchingData = (limit, offset, params) => async (dispatch) => {
  dispatch(fetchDriversDispatchingList(limit, offset, params));
  dispatch(fetchKpiData('drivers', params));
};

export const setLoadsDispatchingList = (list) => ({
  type: SET_LOADS_DISPATCHING_LIST,
  list,
});

export const setLoadsDispatchingListCount = (count) => ({
  type: SET_LOADS_DISPATCHING_LIST_COUNT,
  count
});

export const fetchLoadsDispatchingList = (limit, offset, params) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  instance
    .post(`/dispatching/loads-options/?limit=${limit}&offset=${offset}`, params)
    .then((res) => {
      batch(() => {
        dispatch(setLoadsDispatchingList(res.data.results));
        dispatch(setLoadsDispatchingListCount(res.data.count));
        dispatch(setDispatchingHistoryActive(res.data.show_history));
        dispatch(setDispatchingEngineRunId(res.data.engine_run_id));
        dispatch(setPlanningHorizon(res.data.planning_horizon));
      });
      Promise.resolve();
    })
    .catch((err) => console.error(err));
  dispatch(ShowScenarioLoader(false));
};

export const fetchLoadsDispatchingData = (limit, offset, params) => async (dispatch) => {
  dispatch(fetchLoadsDispatchingList(limit, offset, params));
  dispatch(fetchKpiData('loads', params));
};

export const setDriverStatus = (status) => ({
  type: SET_DRIVER_STATUS,
  status
});

export const fetchDispatchingDriverStatus = (driverId) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  await instance
    .get(`/dispatching/pre-dispatch-state/${driverId}/`)
    .then((res) => {
      dispatch(setDriverStatus(res.data.results));
    })
    .catch((err) => console.error(err));
  dispatch(ShowScenarioLoader(false));
};

export const setKpiData = (charts) => ({
  type: SET_KPI_CHARTS_DATA,
  charts
});

export const fetchKpiData = (which, params) => async (dispatch) => {
  dispatch(ShowKpiLoader(true));
  await instance
    .post(`/dispatching/${which}-options/kpis/`, params)
    .then((res) => {
      if (res.data.success) {
        const { driver_kpis, load_kpis, driver_availability, load_availability } = res.data.results;
        dispatch(setKpiData({ driver_kpis, load_kpis }));
        dispatch(setDriverAvailabilityData(driver_availability));
        dispatch(setLoadAvailabilityData(load_availability));
      }
    })
    .catch((err) => console.error(err));
  dispatch(ShowKpiLoader(false));
};

export const setDispatchingAssignmentList = (list) => ({
  type: SET_DISPATCHING_ASSIGNMENT_LIST,
  list
});

export const setDispatchingAssignmentListCount = (count) => ({
  type: SET_DISPATCHING_ASSIGNMENT_LIST_COUNT,
  count
});

export const fetchDispatchingAssignmentList = (limit, offset, params) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  await instance
    .post(`/dispatching/driver-movement-assignments/?limit=${limit}&offset=${offset}`, params)
    .then((res) => {
      batch(() => {
        dispatch(setDispatchingAssignmentList(res.data.results));
        dispatch(setDispatchingAssignmentListCount(res.data.count));
      });
    })
    .catch((err) => console.error(err));
  dispatch(ShowScenarioLoader(false));
};

export const setDriverDispatchingFilterOptions = (options) => ({
  type: SET_DRIVER_DISPATCHING_FILTER_OPTIONS,
  options
});

export const fetchDriverDispatchingFilterOptions = (fakeOption = null) => async (dispatch) => {
  await instance
    .get('/dispatching/filter-options/')
    .then((res) => {
      if (!!fakeOption && !!fakeOption?.mock) {
        console.warn('MOCK data still being added to ENDPOINT /dispatching/filter-options/ response.');
        // add mocks to res.data
        const mockData = {
          driver_ids: driverIDs,
          predispatch_final_loc: cityStates,
        };

        Object.entries(mockData).forEach(([fakeKey, mockValue]) => {
          res.data[fakeKey] = mockValue;
        });
      }

      const results = res.data;

      const valueObj = Object.entries(results)
        .reduce((valueOptionsTable, [keyFilter, optionsArr]) => {
          valueOptionsTable[keyFilter] = optionsArr.map((option) => ({ value: option }));
          return valueOptionsTable;
        }, {});
      dispatch(setDriverDispatchingFilterOptions(valueObj));
    })
    .catch((err) => console.error(err));
};

export const setLoadDispatchingFilterOptions = (options) => ({
  type: SET_LOAD_DISPATCHING_FILTER_OPTIONS,
  options
});

export const fetchLoadDispatchingFilterOptions = (engineRunId) => async (dispatch) => {
  await instance
    .get(`/dispatching/assign-by-load-filter-options/${engineRunId}/`)
    .then((res) => {
      const shipper_ids = res.data.shipper_id.map((id) => ({ value: id }));
      const shipper_names = res.data.shipper_name?.map((name) => ({ value: name }));
      const region_codes = res.data.region_codes?.map((code) => ({ value: code }));
      dispatch(setLoadDispatchingFilterOptions({ shipper_ids, region_codes, shipper_names }));
    })
    .catch((err) => console.error(err));
};

export const setDispatchingDriverManagerFilterOptions = (options) => ({
  type: SET_DISPATCHING_DRIVER_MANAGER_FILTER_OPTIONS,
  options
});

export const fetchDispatchingDriverManagerFilterOptions = () => async (dispatch) => {
  await instance
    .get(`/dispatching/dropdown-driver-manager-options/`)
    .then((res) => {
      const asObject = res.data.results.map((mgr) => ({ driver_manager: mgr }));
      dispatch(setDispatchingDriverManagerFilterOptions(asObject));
    })
    .catch((err) => console.error(err));
};

export const setDropdownLoadOptions = (loadOptions) => ({
  type: SET_DISPATCHING_LOAD_OPTIONS,
  loadOptions
});

export const fetchDropdownLoadOptions = () => async (dispatch) => {
  await instance
    .get('/dispatching/dropdown-load-options/')
    .then((res) => {
      const list = res.data.results.map((loadId) => ({ value: loadId }));
      dispatch(setDropdownLoadOptions(list));
    })
    .catch((err) => console.error(err));
};

export const setLoadingStatus = (loadingStatus) => ({
  type: SET_LOADING_STATUS,
  loadingStatus
});

export const setLoadingStatusOn = () => (dispatch) => {
  dispatch(setLoadingStatus(true));
};

export const setLoadingStatusOff = () => (dispatch) => {
  dispatch(setLoadingStatus(false));
};

export const setOfferedLoadDetails = (details) => ({
  type: SET_OFFERED_LOAD_DETAILS,
  details
});

export const fetchOfferedLoadDetails = (movementOptionId) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  await instance
    .get(`/dispatching/movement-option/${movementOptionId}/`)
    .then((res) => dispatch(setOfferedLoadDetails(res.data)))
    .catch((err) => console.error(err));

  batch(() => {
    dispatch(ShowScenarioLoader(false));
    dispatch(setLoadingStatus(false));
  });
};

export const setDriversList = (driversList) => ({
  type: SET_DRIVERS_LIST,
  driversList
});

export const clearDriversList = () => (dispatch) => dispatch(setDriversList([]));

export const fetchDriversList = () => async (dispatch) => {
  await instance
    .get('/dispatching/drivers/')
    .then((res) => dispatch(setDriversList(res.data)))
    .catch((err) => console.error(err));
};

// export const saveDriverRule = (payload) => async (dispatch) => {
//   await instance
//     .post('/dispatching/driver-rules/?rule_type=driver_rule', payload)
//     .then((res) => console.error(res))
//     .catch((err) => console.error(err));
// };

// export const updateDriverRule = (payload) => async (dispatch) => {
//   await instance
//     .patch(`/dispatching/driver-rules/${payload.driver_id}`, payload)
//     .then((res) => console.error(res))
//     .catch((err) => console.warn(err));
// };

export const clearAssignments = () => ({
  type: CLEAR_SELECTED_LOAD_ASSIGNMENT
});

export const clearLoadAssignments = () => (dispatch) => {
  dispatch(clearAssignments());
};

export const addingDriverID = (driverID, loadID, movementID) => ({
  type: ADD_SELECTED_LOAD_ASSIGNMENT,
  payload: { driverID, loadID, movementID },
});

export const addLoadAssignment = (driverID, loadID, movementID) => (dispatch) => {
  dispatch(addingDriverID(driverID, loadID, movementID));
};

export const removeLoadAssignByDriverID = (driverID) => ({
  type: REMOVE_SELECTED_LOAD_ASSIGNMENT,
  driverID,
});

export const removeLoadAssignment = (driverID) => (dispatch) => {
  dispatch(removeLoadAssignByDriverID(driverID));
};

export const setTopLoadOptions = (topLoadOptions) => ({
  type: SET_TOP_LOAD_OPTIONS,
  topLoadOptions,
});

export const setTopLoads = (options) => (dispatch) => {
  dispatch(setTopLoadOptions(options));
};

export const removeTopLoadOption = (topLoadOption) => ({
  type: REMOVE_TOP_LOAD_OPTION,
  topLoadOption,
});

export const removeTopLoad = (option) => (dispatch) => {
  dispatch(removeTopLoadOption(option));
};

export const clearTopLoadOptions = (_topLoadOption) => ({
  type: CLEAR_TOP_LOAD_OPTIONS,
});

export const clearTopLoads = (_option) => (_dispatch) => {};

export const checkForNewData = (dataUpdated, taskId, latestRunUpdated) => ({
  type: CHECK_OR_SET_DATA_UPDATE,
  dataUpdated,
  taskId,
  latestRunUpdated
});

export const pollDispatchingUpdate = () => ({
  type: POLLING_UPDATE,
});

export const unassignLoadFromDriver = (payload, limit, offset, params) => async (dispatch) => {
  await instance
    .post('/dispatching/drivers/loads-unassign/', payload)
    .then(() => {
      batch(() => {
        dispatch(fetchDispatchingAssignmentList(limit, offset, params));
        dispatch(fetchDriversDispatchingList(limit, offset, params));
      });
    })
    .catch((err) => console.error(err));
};

export const setDispatchingAssignmentHistory = (history) => ({
  type: SET_DISPATCHING_ASSIGNMENT_HISTORY,
  history
});

export const setDispatchingAssignmentHistoryCount = (historyCount) => ({
  type: SET_DISPATCHING_ASSIGNMENT_HISTORY_COUNT,
  historyCount
});

export const setDispatchingAssignmentHistoryTiming = (expirationTiming) => ({
  type: SET_DISPATCHING_HISTORY_EXPIRATION_TIMING,
  expirationTiming
});

export const fetchDispatchingAssignmentHistory = (limit, offset, params) => async (dispatch) => {
  dispatch(ShowScenarioLoader(true));
  await instance
    .post(`/dispatching/assignment-history/?limit=${limit}&offset=${offset}`, params)
    .then((res) => {
      batch(() => {
        dispatch(setDispatchingAssignmentHistory(res.data.results));
        dispatch(setDispatchingAssignmentHistoryCount(res.data.count));
        dispatch(setDispatchingAssignmentHistoryTiming(res.data.expiration_timing));
      });
    })
    .catch((err) => console.error(err));
  dispatch(ShowScenarioLoader(false));
};

const [SET_ONE_ASSIGN_BY_LOAD, setOneByLoadsChoice] = createActionSet('SET_ONE_ASSIGN_BY_LOAD');
const [ADD_ASSIGN_BY_LOAD, addByLoadsChoice] = createActionSet('ADD_ASSIGN_BY_LOAD');
const [BATCH_ADD_ASSIGN_BY_LOAD, batchAddByLoadsChoice] = createActionSet('BATCH_ADD_ASSIGN_BY_LOAD');
const [REMOVE_ASSIGN_BY_LOAD, removeByLoadsChoice] = createActionSet('REMOVE_ASSIGN_BY_LOAD');
const [CLEAR_ASSIGN_BY_LOAD, clearByLoadsChoice] = createActionSet('CLEAR_ASSIGN_BY_LOAD');

export const setSingleAssignByLoad = (loadID, driverID, movementID) => (dispatch) => {
  dispatch(setOneByLoadsChoice({ loadID, driverID, movementID }));
};

export const addAssignByLoad = (loadID, driverID, movementID) => (dispatch) => {
  dispatch(addByLoadsChoice({ loadID, driverID, movementID }));
};

export const removeAssignByLoad = (loadID) => (dispatch) => {
  dispatch(removeByLoadsChoice({ loadID }));
};

export const clearAssignByLoad = (loadID) => (dispatch) => {
  dispatch(clearByLoadsChoice({ loadID }));
};

export const batchAddAssignByLoad = (loadIds, driverIds, mvmtIds) => (dispatch) => {
  dispatch(batchAddByLoadsChoice({ loadIds, driverIds, movementIds: mvmtIds }));
};

export { BATCH_ADD_ASSIGN_BY_LOAD, SET_ONE_ASSIGN_BY_LOAD, ADD_ASSIGN_BY_LOAD, REMOVE_ASSIGN_BY_LOAD, CLEAR_ASSIGN_BY_LOAD };
