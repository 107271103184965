import React from 'react';
import { CSSObject, Box, InputLabel } from '@mui/material';
import LockSharp from '@mui/icons-material/LockSharp';
import { ODSlideOut, ODCheckbox } from '@OptimalDynamics/core-ai-common-ui';

export interface BaseColumnHeader {
  label: string;
  visible: boolean;
  selectionDisabled: boolean;
}

interface ColumnHeaderSettingsProps {
  open: boolean;
  onClose: () => void;
  onToggle: (_column: BaseColumnHeader, _index: number) => void;
  columns: BaseColumnHeader[];
  onReset: () => void;
  sx?: CSSObject
}

function ColumnHeaderSettings({ open, onClose, onToggle, columns, onReset, sx = {} }: ColumnHeaderSettingsProps) {
  return (
    <ODSlideOut
      open={open}
      title="Table Settings"
      onClose={onClose}
      actionText="Reset"
      onAction={onReset}
      sx={sx}
    >
      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '2px', overflow: 'auto' }}>
        <InputLabel>Select Table Column Headers</InputLabel>
        {columns?.map((column, index) => (
          <Box
            key={`column-${index}`}
            id={`column-header-option-${index}`}
            sx={{
              height: '40px',
              minHeight: '40px',
              borderRadius: '4px',
              px: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              ...(column.selectionDisabled ? { backgroundColor: 'colors.neutral0', color: 'text.disabled' }
                : column.visible ? { backgroundColor: 'accent', color: 'text.primary' }
                  : { color: 'text.secondary' }
              )
            }}
          >
            {column.selectionDisabled ? <LockSharp /> : (
              <ODCheckbox
                onClick={() => onToggle(column, index)}
                checked={column.visible}
                disabled={column.selectionDisabled}
              />
            )}
            {column.label}
          </Box>
        ))}
      </Box>
    </ODSlideOut>
  );
}

export default ColumnHeaderSettings;
