import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import HomeSharp from '@mui/icons-material/HomeSharp';
import EventNoteSharpIcon from '@mui/icons-material/EventNoteSharp';
import { ODDialog } from '@OptimalDynamics/core-ai-common-ui/';
import { AMERICAN_DATE_TIME, getLocalizedTimeRange } from '../../../utils/datetimes';
import { NextEventDialogProps, NextEventProps } from '../types';
import { SharedTimeLocationRow } from '../DetailCard/DetailCardTimeline';

export const NextEvent = ({ event }: NextEventProps) => {
  const { event_type, start_time, end_time, timezone, location } = event;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1 }}>
      <HomeSharp sx={{ fontSize: 36, mr: 2 }} />
      <Box sx={{ display: 'block' }}>
        <Typography><strong>{event_type}</strong></Typography>
        <SharedTimeLocationRow time={getLocalizedTimeRange(start_time, end_time, timezone, AMERICAN_DATE_TIME)} location={location} />
      </Box>
    </Box>
  );
};

const NextEventDialog = ({
  open,
  handleClose,
  row,
  sx = {},
}: NextEventDialogProps) => {
  if (!row) return null;
  const multipleEvents = row.length > 1;
  const events = row.map((event) => (
    <Box sx={{ borderRadius: '4px', border: '1px solid', borderColor: 'level3', p: 0, mb: multipleEvents ? 2 : 0 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1 }}>
        <EventNoteSharpIcon sx={{ mr: 2 }} />
        <Typography fontWeight={600}>Scheduled Event</Typography>
      </Box>
      <Divider sx={{ color: 'level3' }} />
      <NextEvent event={event} />
    </Box>
  )
  );

  return (
    <ODDialog
      open={open}
      title={`Next Scheduled Event${multipleEvents ? 's' : ''}`}
      onClose={handleClose}
      sx={{ zIndex: '1500 !important', ...sx }}
    >
      {
        events
      }
    </ODDialog>
  );
};

export default NextEventDialog;
