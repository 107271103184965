import React, { useState } from 'react';
import PersonSharp from '@mui/icons-material/PersonSharp';
import PanToolSharp from '@mui/icons-material/PanToolSharp';
import HomeSharp from '@mui/icons-material/HomeSharp';
import ThreeSixtySharp from '@mui/icons-material/ThreeSixtySharp';
import { Grid, Typography } from '@mui/material';
import { ODSlideOut, ODLaneIcon } from '@OptimalDynamics/core-ai-common-ui/';
import ODAccordion from '../../../common/ODAccordion';
import SlideoutLineItem from '../../../common/SlideoutLineItem';
import { getLocalizedTime, getMinHosFigure } from '../../../utils/datetimes';
import HOSDialog from './HOSDialog';
import { LOADED, IN_PROGRESS, EMPTY, HOLD, TIME_AT_HOME, PRESIM_END } from '../helpers/constants';
import { generateTimestamp } from '../DetailCard/DetailCardTimeline';

const DriverLoadDetailSlideout = ({ open, handleClose, driverDetail, driverName }) => {
  const [pickUpHosDialogData, setPickUpHosDialogData] = useState(null);
  const [dropOffHosDialogData, setDropOffHosDialogData] = useState(null);
  const renderAccordion = (order, key, icon, label, detail, content) => (
    <ODAccordion
      defaultExpanded={order === 1}
      key={key}
      summary={(
        <div className="accordion-heading" style={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
          <Typography fontWeight={600} sx={{ marginRight: '8px' }}>{order}</Typography>
          {icon}
          <Typography>
            <strong>
              {label}
              {detail && ': '}
            </strong>
            {detail}
          </Typography>
        </div>
      )}
    >
      {content}
    </ODAccordion>
  );

  return (
    <ODSlideOut
      open={open}
      title="Driver Plan"
      onClose={handleClose}
      style={{ zIndex: 1400 }}
      elevation={0}
    >
      <Grid
        container
        sx={{
          marginBottom: '16px',
          padding: '0px 16px',
          height: '40px',
          alignItems: 'center',
          columnGap: '8px',
          backgroundColor: 'accent',
          borderRadius: '4px'
        }}
      >
        <PersonSharp />
        <Typography id="driver-current-load-driver-id">{driverName}</Typography>
      </Grid>
      {driverDetail && driverDetail.map((movement, index) => {
        if (movement.move_type === LOADED) {
          return renderAccordion(
            movement.order_index,
            movement.load_id,
            <ODLaneIcon />,
            'Load ID',
            movement.load_id,
            <>
              <SlideoutLineItem title="Pick Up Location:" content={(movement.status !== IN_PROGRESS && movement.pickup_loc) || '-'} />
              <SlideoutLineItem title="Drop Off Location:" content={movement.dropoff_loc || '-'} />
              <SlideoutLineItem title="Estimated Pick Up Time:" content={(movement.status !== IN_PROGRESS && getLocalizedTime(movement.start_time, movement.pickup_timezone)) || '-'} />
              <SlideoutLineItem title="Origin Type:" content={!!movement.orig_live_flag ? 'Live Load' : 'PreLoad'} />
              <SlideoutLineItem title="Estimated Drop Off Time:" content={getLocalizedTime(movement.end_time, movement.dropoff_timezone) || '-'} />
              <SlideoutLineItem title="Destination Type:" content={!!movement.dest_drop_flag ? 'PreLoad' : 'Live Unload'} />
              <SlideoutLineItem title="Estimated Pick Up HOS:" contentCallback={movement.status !== IN_PROGRESS && (() => setPickUpHosDialogData(movement))} content={(movement.status !== 'In Progress' && getMinHosFigure(movement.hos_start_status)) || '-'} />
              <SlideoutLineItem title="Estimated Drop Off HOS:" contentCallback={() => setDropOffHosDialogData(movement)} content={getMinHosFigure(movement.hos_end_status) || '-'} />
              <SlideoutLineItem title="Expected Time From Delivery:" content={movement.exp_time_from_delivery || '-'} />
              <SlideoutLineItem title="Current Distance from Destination:" content={movement.dist_from_dest || '-'} />
              <SlideoutLineItem title="No. of Remaining Stops:" content={movement.num_stops_left || 0} />
              <SlideoutLineItem title="Revenue:" content={`$${movement.revenue}` || '-'} />
              <SlideoutLineItem title="Status:" content={movement.status || '-'} />
              <SlideoutLineItem title="Pick Up Appointment Type:" content={movement.pickup_appt_type || '-'} />
              <SlideoutLineItem title="Drop Off Appointment Type:" content={movement.dropoff_appt_type || '-'} />
            </>
          );
        }
        if (movement.move_type === HOLD) {
          return renderAccordion(
            movement.order_index,
            `hold-${index}`,
            <PanToolSharp style={{ height: '16px', width: '16px' }} />,
            'Movement Type',
            'Wait For Loads',
            <>
              <SlideoutLineItem title="Start:" content={getLocalizedTime(movement.start_time, movement.hold_timezone) || '-'} />
              <SlideoutLineItem title="End:" content={getLocalizedTime(movement.end_time, movement.hold_timezone) || '-'} />
              <SlideoutLineItem title="Location:" content={movement.hold_loc || '-'} />
            </>
          );
        }
        if (movement.move_type === TIME_AT_HOME) {
          return renderAccordion(
            movement.order_index,
            `hold-${index}`,
            <HomeSharp />,
            'Movement Type',
            'Home Time',  
            <>
              <SlideoutLineItem title="Home Start:" content={movement.start_time === 'Now' ? 'Now' : getLocalizedTime(movement.start_time, movement.location_timezone) || '-'} />
              <SlideoutLineItem title="Home End:" content={getLocalizedTime(movement.end_time, movement.location_timezone) || '-'} />
              <SlideoutLineItem title="Location:" content={movement.location || '-'} />
            </>
          );
        }
        if (movement.move_type.startsWith(EMPTY)) {
          return renderAccordion(
            movement.order_index,
            `hold-${index}`,
            <HomeSharp />,
            'Movement Type',
            movement.move_type.split('_').map((d) => d.charAt(0).toUpperCase() + d.slice(1).toLowerCase()).join(' '),
            <>
              <SlideoutLineItem title="Origin:" content={movement.origin_loc || '-'} />
              <SlideoutLineItem title="Destination:" content={movement.dest_loc || '-'} />
              <SlideoutLineItem title="Empty Miles:" content={movement.empty_miles || '-'} />
            </>
          );
        }
        if (movement.move_type === PRESIM_END) {
          return renderAccordion(
            movement.order_index,
            `presim-end-${index}`,
            <ThreeSixtySharp />,
            'Empty',
            null,
            <>
              <SlideoutLineItem title="Destination:" content={movement.dest_loc || '-'} />
              <SlideoutLineItem title="ETA:" content={generateTimestamp(movement.end_time, movement.dest_timezone) || '-'} />
            </>
          );
        }
        return null;
      })}
      <HOSDialog
        open={!!pickUpHosDialogData}
        handleClose={() => setPickUpHosDialogData(null)}
        sx={{ '&.MuiModal-root.MuiDialog-root': { zIndex: 1400 } }}
        forecastText="Clocks are estimated for pick up at"
        row={{
          hos_status: pickUpHosDialogData?.hos_start_status,
          road_mins_remain_end: pickUpHosDialogData?.road_mins_remain_start_info || '-',
          duty_mins_remain_end: pickUpHosDialogData?.duty_mins_remain_start_info || '-',
          eight_days_mins_remain_end: pickUpHosDialogData?.eight_days_mins_remain_start_info || '-',
          avail_for_dispatch_et: pickUpHosDialogData?.start_time,
          avail_timezone: pickUpHosDialogData?.pickup_timezone
        }}
      />
      <HOSDialog
        open={!!dropOffHosDialogData}
        handleClose={() => setDropOffHosDialogData(null)}
        sx={{ '&.MuiModal-root.MuiDialog-root': { zIndex: 1400 } }}
        forecastText="Clocks are estimated for drop off at"
        row={{
          hos_status: dropOffHosDialogData?.hos_end_status,
          road_mins_remain_end: dropOffHosDialogData?.road_mins_remain_end_info || '-',
          duty_mins_remain_end: dropOffHosDialogData?.duty_mins_remain_end_info || '-',
          eight_days_mins_remain_end: dropOffHosDialogData?.eight_days_mins_remain_end_info || '-',
          avail_for_dispatch_et: dropOffHosDialogData?.end_time,
          avail_timezone: dropOffHosDialogData?.dropoff_timezone
        }}
      />
    </ODSlideOut>
  );
};

export default DriverLoadDetailSlideout;
