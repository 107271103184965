import React, { ReactElement, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import { assign, cloneDeep, find, pick } from 'lodash';
import { ODAppMenu, ODAvatar, ODTooltip } from '@OptimalDynamics/core-ai-common-ui';
import { AppBar, Grid, Paper } from '@mui/material';
import NotInterestedSharp from '@mui/icons-material/NotInterestedSharp';
import HistorySharp from '@mui/icons-material/HistorySharp';
import RuleSharp from '@mui/icons-material/RuleSharp';
import { GlobalAlertsDrawerContextWrapper } from '../utils/context';
import cookies from '../utils/cookies';
import { GetUserData, GetUserSettings } from '../store/actions/auth';
import { FetchAllOrgExecuteConfigs } from '../store/actions/configStore';
import { FetchUserSettings, UpdateUserSettings } from '../store/actions/userSettings';
import { GetEngineRun, GetAlerts } from '../store/actions/notificationAction';
import draftStore from '../utils/draftStore';
import { RootState } from '../store/reducers';
import { DEFAULT_TIMEZONE, getLocalizedTime } from '../utils/datetimes';
import { BY_DRIVER, BY_LOAD, DISPATCHING_HEADERS, initialByDriversColumns, initialSourceColumns, initialByLoadsColumns, LOAD_SEARCH_EXTERNAL_COLS, LOAD_SEARCH_EXTERNAL_COLS_V2, initialSourceColumnsWithFeasibilityChecker } from '../components/dispatching/helpers/constants';
import instance from '../utils/axios_instance';
import { TwoWayLoadHeaders } from '../defaults/headers';
import { LOAD_ACCEPTANCE_HEADERS } from '../components/loadAcceptance/loadAcceptanceConstants';
import { APP_PAGE_FLAGS, TRAILER_ASSIGNMENTS, DISPATCHING, LOAD_ACCEPTANCE, TACTICAL_PROCUREMENT, LOAD_DEMAND_FORECAST, APP_SUBPAGES } from '../AppConstants';
import { ZendeskWidgetCommander } from './zendesk.types';

const LAST_SYNC_ZD_FIELD_ID = '12233454439053';

interface MenuOption {
  title: string;
  matchKey: string;
  icon: ReactElement;
  selected: boolean;
  onClick: () => void;
}

declare global {
  interface Window {
    openWidget: () => void;
    zE: ZendeskWidgetCommander;
  }
}

interface RightNavButtonProps {
  title: string;
  onClick: () => void;
  icon: ReactElement;
}

const LayoutAppBar = ({ children }: { children: React.ReactNode }) => (
  <AppBar
    position="sticky"
    sx={{
      width: 56,
      backgroundColor: 'colors.white',
      borderLeft: 1,
      borderColor: 'level3',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '2px',
      alignItems: 'center',
      zIndex: 1300,
      pt: 1,
      height: '100vh'
    }}
    elevation={0}
  >
    {children}
  </AppBar>
);

const RightNavButton = ({ title, onClick, icon }: RightNavButtonProps) => (
  <ODTooltip title={title} placement="left">
    <div>
      <ODAvatar variant="transparent" onClick={onClick}>
        {icon}
      </ODAvatar>
    </div>
  </ODTooltip>
);

const Layout = () => {
  const [token, setToken] = useState<null | string>(null);
  const [menuOptions, setMenuOptions] = useState<MenuOption[]>([]);
  const [helpOpen, setHelpOpen] = useState(false);
  const { engineRun } = useSelector((state: RootState) => state.notificationReducer) || {};
  const dispatchingDataUpdated = useSelector((state: RootState) => state.dispatchingReducer.dataUpdated) || '';
  const dispatchingEngineRunId = useSelector((state: RootState) => state.dispatchingReducer.engineRunId) || '';
  const notificationEngineRunId = useSelector((state: RootState) => state.notificationReducer.engineRun) || '';
  const notificationDataUpdated = useSelector((state: RootState) => state.notificationReducer.engineRunUpdate) || '';
  const loadAcceptanceDataUpdated = useSelector((state: RootState) => state.loads.dataUpdated) || '';
  const loadAcceptanceEngineRunId = useSelector((state: RootState) => state.loads.engineRunId) || '';
  const forecastingEngineRunId = useSelector((state: RootState) => state.forecasting.engineRunId) || '';
  const forecastingDataUpdated = useSelector((state: RootState) => moment.tz(state.forecasting.lastSynced, DEFAULT_TIMEZONE).tz(moment.tz.guess()).format(`YYYY-MM-DDTHH:mm zZZ`)) || '';
  const launchDarklyUserIdentified = useSelector((state: RootState) => state.auth.launchDarklyUserIdentified);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const allFlags = useFlags();
  const { showFeedbackTracker, useSourceFeasibilityFeature } = allFlags;
  const pageFlags = pick(allFlags, APP_PAGE_FLAGS);
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
  const [open, setOpen] = useState(false);
  const [initialMenuOptions, setInitialMenuOptions] = useState<MenuOption[]>([]);
  const currentPage = location?.pathname.split('/')[1];

  // the following should be used for updating the structure of parts of userSettings. increment the version whenever an update is necessary.
  const versionNumber = '1.13';
  const checkAndUpdateUserSettingsSchema = async () => {
    await instance
      .get('/user-settings/')
      .then((res) => {
        if (res.data?.version !== versionNumber) {
          // dispatching
          let driversColumns = cloneDeep(res.data[DISPATCHING_HEADERS]?.[BY_DRIVER] || initialByDriversColumns);
          let loadsColumns = cloneDeep(res.data[DISPATCHING_HEADERS]?.[BY_LOAD] || initialByLoadsColumns);
          let sourceColumns = cloneDeep(res.data[LOAD_SEARCH_EXTERNAL_COLS]) || initialSourceColumns;
          let sourceColumnsV2 = cloneDeep(res.data[LOAD_SEARCH_EXTERNAL_COLS_V2]) || initialSourceColumnsWithFeasibilityChecker;

          driversColumns = cloneDeep(initialByDriversColumns).map((defaultCol) => {
            const userCol = find(driversColumns, { value: defaultCol.value });
            if (userCol) assign(defaultCol, { visible: userCol.visible });
            return defaultCol;
          });

          loadsColumns = cloneDeep(initialByLoadsColumns).map((defaultCol) => {
            const userCol = find(loadsColumns, { value: defaultCol.value });
            if (userCol) assign(defaultCol, { visible: userCol.visible });
            return defaultCol;
          });

          sourceColumns = cloneDeep(initialSourceColumns).map((defaultCol) => {
            const userCol = find(sourceColumns, { value: defaultCol.value });
            if (userCol) assign(defaultCol, { visible: userCol.visible });
            return defaultCol;
          });

          if (useSourceFeasibilityFeature) {
            sourceColumnsV2 = cloneDeep(initialSourceColumnsWithFeasibilityChecker).map((defaultCol) => {
              const userCol = find(sourceColumnsV2, { value: defaultCol.value });
              if (userCol) {
                assign(defaultCol, { visible: userCol.visible });
                if (['origin_to_pickup_miles', 'destination_to_dropoff_miles'].includes(userCol.value)) {
                  assign(defaultCol, { width: 150 });
                }
              }
              return defaultCol;
            });
          } 

          // load acceptance
          let loadAcceptanceHeaders = cloneDeep(res.data?.[LOAD_ACCEPTANCE_HEADERS] || TwoWayLoadHeaders);
          loadAcceptanceHeaders = cloneDeep(TwoWayLoadHeaders).map((defaultCol) => {
            const userCol = find(loadAcceptanceHeaders, { sortKey: defaultCol.sortKey });
            if (userCol) assign(defaultCol, { visible: userCol.visible });
            return defaultCol;
          });

          const tempUserSettings = cloneDeep(res.data);
          tempUserSettings[DISPATCHING_HEADERS] = { ...res.data[DISPATCHING_HEADERS], [BY_DRIVER]: driversColumns, [BY_LOAD]: loadsColumns };
          tempUserSettings[LOAD_SEARCH_EXTERNAL_COLS] = sourceColumns;
          tempUserSettings[LOAD_SEARCH_EXTERNAL_COLS_V2] = sourceColumnsV2;
          dispatch(UpdateUserSettings({ ...tempUserSettings, [LOAD_ACCEPTANCE_HEADERS]: loadAcceptanceHeaders, version: versionNumber }));
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (!!token) {
      checkAndUpdateUserSettingsSchema();
      dispatch(FetchAllOrgExecuteConfigs());
    }
  }, [token]);

  useEffect(() => {
    setOpen(
      // if there is no menuOpen session key and window is > 2140px, return true; otherwise return menuOpen session value
      draftStore.getSession('menuOpen') === null
        ? window.matchMedia('(min-width: 2140px)').matches
        : draftStore.getSession('menuOpen')
    );
  }, []);

  useEffect(() => {
    getToken();
  }, [isLoading]);

  useEffect(() => {
    if (!!token) {
      dispatch(FetchUserSettings());
    }
  }, [token, dispatch]);
  
  const getRequiredData = async (newToken: string) => {
    if (newToken) {
      dispatch(GetUserData());
      dispatch(GetEngineRun());
      dispatch(GetUserSettings());
      setToken(newToken);
    }
  };

  useEffect(() => {
    if (engineRun) {
      dispatch(GetAlerts(engineRun));
    }
  }, [engineRun]);

  const getToken = async () => {
    if (isLoading) return;
    if (!isAuthenticated) {
      navigate('/login', { state: { from: location.pathname } });
    }
    const newToken = await getAccessTokenSilently();
    const subdomain = window.location.hostname === 'localhost' ? '' : `${window.location.hostname.split('.')[0]}_`;
    cookies.set(`${subdomain}token`, newToken);
    setToken(newToken);
    getRequiredData(newToken);
  };

  const logoutAuth = async () => {
    logout({ returnTo: window.location.origin });
  };

  // checks menu options against their feature flag values
  useEffect(() => {
    const flaggedMenuOptions: MenuOption[] = [];
    if (launchDarklyUserIdentified) {
      Object.entries(APP_SUBPAGES).forEach(([pagePath, details]) => {
        const { flag: _flag, ...deets } = details;
        if (allFlags[details.flag]) {
          flaggedMenuOptions.push(
            { ...deets, matchKey: pagePath, selected: false, onClick: () => navigate(`/${pagePath}`) }
          );
        }
      });
      setInitialMenuOptions(flaggedMenuOptions);
    }
  }, [launchDarklyUserIdentified, ...Object.values(pageFlags)]);

  // determines which menu option has the `selected` state
  useEffect(() => {
    const updatedMenuOptions = initialMenuOptions.map((option) => {
      if (currentPage === '') return option;
      option.selected = currentPage.startsWith(option.matchKey);
      return option;
    });
    setMenuOptions(updatedMenuOptions);
  }, [location, initialMenuOptions, ...Object.values(pageFlags)]);

  const handleHideZenDeskWidget = (shouldClose = true) => {
    window.zE('webWidget', 'hide');
    if (shouldClose) window.zE('webWidget', 'close');
    setHelpOpen(false);
  };

  window.zE('webWidget:on', 'open', () => {
    setHelpOpen(true);
  });

  window.zE('webWidget:on', 'close', () => {
    handleHideZenDeskWidget();
  });

  window.zE('webWidget:on', 'chat:popout', () => {
    handleHideZenDeskWidget(false);
  });

  // gets the correct engine run data depending on the user's url location
  const getRelevantData = () => {
    switch (true) {
      case currentPage.startsWith(DISPATCHING):
        return [dispatchingEngineRunId, dispatchingDataUpdated];
      case currentPage.startsWith(LOAD_ACCEPTANCE):
        return [loadAcceptanceEngineRunId, loadAcceptanceDataUpdated];
      case currentPage.startsWith(TACTICAL_PROCUREMENT):
        return [notificationEngineRunId, notificationDataUpdated];
      case currentPage.startsWith(LOAD_DEMAND_FORECAST):
        return [forecastingEngineRunId, forecastingDataUpdated];
      default:
        return ['N/A', 'N/A'];
    }
  };

  const hasRightBar = [DISPATCHING, TRAILER_ASSIGNMENTS].includes(currentPage);

  return (
    <GlobalAlertsDrawerContextWrapper>
      <div style={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <ODAppMenu
              menuOptions={menuOptions}
              onClickLogout={logoutAuth}
              open={open}
              showFeedbackTracker={showFeedbackTracker}
              onToggle={() => {
                draftStore.setSession('menuOpen', !open);
                setOpen(!open);
              }}
              onClickHelp={() => {
                if (helpOpen) {
                  handleHideZenDeskWidget();
                } else {
                  const [relevantEngineRunId, relevantSyncTime] = getRelevantData();
                  window.openWidget();
                  window.zE('webWidget', 'updateSettings', {
                    webWidget: {
                      contactForm: {
                        fields: [
                          { id: LAST_SYNC_ZD_FIELD_ID,
                            prefill: {
                              '*': relevantSyncTime === 'N/A'
                                ? relevantSyncTime
                                : `${getLocalizedTime(relevantSyncTime.replace(/(EST|EDT)/gm, ''), moment.tz.guess(), 'YYYY-MM-DDTHH:mm ZZ', 'MM/DD HH:mm z')} (ID: ${relevantEngineRunId})`
                            }
                          }
                        ]
                      }
                    }
                  });
                }
              }}
              helpActive={helpOpen}
            />
            <Grid item xs={12} style={{ position: 'relative', width: `calc(100% - ${open ? (hasRightBar ? 336 : 280) : 112}px)` }}>
              {token && (
                <Paper square={true} style={{ boxShadow: 'none', position: 'relative' }}>
                  <Outlet />
                </Paper>
              )}
            </Grid>
            {currentPage === DISPATCHING && (
              <LayoutAppBar>
                <RightNavButton title="Assignment History" icon={<HistorySharp />} onClick={() => setSearchParams({ ...Object.fromEntries([...searchParams]), show_history: 'true' })} />
                <RightNavButton title="Driver Rules" icon={<RuleSharp />} onClick={() => setSearchParams({ ...Object.fromEntries([...searchParams]), show_rules: 'true' })} />
                <RightNavButton title="Data Errors & Excluded" icon={<NotInterestedSharp />} onClick={() => setSearchParams({ ...Object.fromEntries([...searchParams]), show_exclusions: 'true' })} />
              </LayoutAppBar>
            )}
            {currentPage.startsWith(TRAILER_ASSIGNMENTS) && (
              <LayoutAppBar>
                <RightNavButton title="Assignment History" icon={<HistorySharp />} onClick={() => setSearchParams({ ...Object.fromEntries([...searchParams]), show_history: 'true' })} />
                <RightNavButton title="Excluded Trailers" icon={<NotInterestedSharp />} onClick={() => setSearchParams({ ...Object.fromEntries([...searchParams]), show_exclusions: 'true' })} />
              </LayoutAppBar>
            )}
          </Grid>
        </Grid>
      </div>
    </GlobalAlertsDrawerContextWrapper>
  );
};

export default Layout;
