/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce, pick, pickBy } from 'lodash';
import { Box, Link, Skeleton, TableSortLabel } from '@mui/material';
import AlarmOnSharp from '@mui/icons-material/AlarmOnSharp';
import ArrowForward from '@mui/icons-material/ArrowForward';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import NotInterestedSharp from '@mui/icons-material/NotInterestedSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import PeopleSharp from '@mui/icons-material/PeopleSharp';
import StarsSharp from '@mui/icons-material/StarsSharp';
import TimelapseSharp from '@mui/icons-material/TimelapseSharp';
import {
  ODButton,
  ODLaneIcon,
  ODRadio,
  ODTable,
  ODTableBody,
  ODTableCell,
  ODTableHead,
  ODTableRow,
  ODTooltip
} from '@OptimalDynamics/core-ai-common-ui';
import { getSyncTimeDiff, SHORT_DATE_TIME_AT_DIV, DATE_TIME_WITH_SEC_T_DIV, DEFAULT_TIMEZONE } from '../../../utils/datetimes';
import { formatWithUnit } from '../../../utils/math';
import instance from '../../../utils/axios_instance';
import { DriverLoadDetailContext } from '../../../utils/context/driverLoadDetailContext';
import { SearchContext } from '../../../utils/context/searchContext';
import SearchMatchHighlight from '../shared/SearchMatchHighlight';
import { ConfirmSearchAssignment } from '../shared/ConfirmSearchAssignment';
import PermitsAndRequirementsDialog from '../shared/PermitsAndRequirementsDialog';
import { confirmAssignment } from '../helpers/dispatchingScripts';
import { BROKERAGE, DRIVER, PICK_UP_DISTANCE_TOOLTIP } from '../helpers/constants';
import { DriverIdentifier, dynamicDescription } from '../helpers/dynamicDescription';
import { DATE_RANGE, SELECT, getHeaderFilter } from '../../../common/headerFilters';
import { SessionContext } from '../../../utils/context/sessionCtx';
import DriverDispatchMatchCell from '../drivers/DriverDispatchMatchCell';
import { dynamicSourceBrokerageDescription } from '../helpers/dynamicSourceBrokerageDescription';

const ASSIGNED = 'Assigned';

const DriversSearch = ({
  currentLoad,
  optimalMatch,
  optimalMatchNode,
  onClean
}) => {
  const { dataUpdated } = useOutletContext();
  const { sessionUid } = useContext(SessionContext);
  const { resetValues, page, setPage, rowsPerPage, setCount, search, setHasResults, setLoading } = useContext(SearchContext);
  const [_, setSearchParams] = useSearchParams();
  const { handleClickDriver } = useContext(DriverLoadDetailContext);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [permitsAndRequirementsDriver, setPermitsAndRequirementsDriver] = useState(null);
  const [rows, setRows] = useState(null);
  const [ordering, setOrdering] = useState('avail_datetime');
  const [sort, setSort] = useState('asc');
  const [driverIdFilter, setDriverIdFilter] = useState('');
  const [tractorIdFilter, setTractorIdFilter] = useState('');
  const [distanceFromPickUpFilter, setDistanceFromPickUpFilter] = useState('');
  const [driverTypeFilter, setDriverTypeFilter] = useState('');
  const [dispatchLocationFilter, setDispatchLocationFilter] = useState('');
  const [availableStartFilter, setAvailableStartFilter] = useState(null);
  const [availableEndFilter, setAvailableEndFilter] = useState(null);
  const [dispatchMatchFilter, setDispatchMatchFilter] = useState('Not Planned');
  const [availableStartCalendarOpen, setAvailableStartCalendarOpen] = useState(false);
  const [availableEndCalendarOpen, setAvailableEndCalendarOpen] = useState(false);
  const [confirmAssignmentOpen, setConfirmAssignmentOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [optimalMatchRow, setOptimalMatchRow] = useState(null);
  const [debouncedSearchHandler] = useState(() => (
    debounce((searchKeyword, filters, params) => fetchDrivers(searchKeyword, filters, params), 300)
  ));
  const optimalMatchOptions = ['No Match', 'Has Match', 'Home Time', 'Planned', 'Not Planned'];
  const {
    disableDispatchingAssignment,
    showSourceSuggestions,
    orgUse2WayDispatching,
    assignBrokerageLoads,
  } = useFlags();

  const LOAD_HEADERS = [
    { label: 'Driver ID', sortKey: 'driver__driver_id', type: SELECT, onChange: setDriverIdFilter, currentValue: driverIdFilter, filterOptionsKey: 'driver_id' },
    { label: 'Tractor ID', sortKey: 'driver__tractor_id', type: SELECT, onChange: setTractorIdFilter, currentValue: tractorIdFilter, filterOptionsKey: 'tractor_id' },
    { label: 'Driver Type', sortKey: 'driver__driver_type', type: SELECT, onChange: setDriverTypeFilter, currentValue: driverTypeFilter, filterOptionsKey: 'driver_type' },
    { label: 'Avail. for Dispatch', sortKey: 'avail_datetime', type: DATE_RANGE, startFilterValue: availableStartFilter, setStartFilterValue: setAvailableStartFilter, endFilterValue: availableEndFilter, setEndFilterValue: setAvailableEndFilter, startCalendarOpen: availableStartCalendarOpen, endCalendarOpen: availableEndCalendarOpen, setStartCalendarOpen: setAvailableStartCalendarOpen, setEndCalendarOpen: setAvailableEndCalendarOpen, startHelperText: 'Available After', endHelperText: 'Available Before' },
    { label: 'Dispatch Location', sortKey: 'predispatch_final_loc', type: SELECT, onChange: setDispatchLocationFilter, currentValue: dispatchLocationFilter, filterOptionsKey: 'dispatch_location' },
    { label: 'Pick Up Distance', sortKey: 'pta_to_pickup_miles', type: SELECT, onChange: setDistanceFromPickUpFilter, currentValue: distanceFromPickUpFilter, filterOptionsKey: 'pta_to_pickup_miles', disabled: true, tooltip: PICK_UP_DISTANCE_TOOLTIP },
    { label: 'Dispatch Match', sortKey: 'optimal_match', width: 120, onChange: setDispatchMatchFilter, currentValue: dispatchMatchFilter, type: SELECT, filterOptionsKey: 'optimal_match_options' },
    { label: 'Permits & Requirements', sortKey: 'permits', type: SELECT, filterOptionsKey: 'permits_options', onChange: () => null, disabled: true }
  ];

  const checkIsDisabled = () => {
    if (disableDispatchingAssignment) return true;
    if (!assignBrokerageLoads && orgUse2WayDispatching && currentLoad?.internal_source === BROKERAGE) return true;
    if (!selectedDriver || !selectedDriver?.active) return true;
    return false;
  };

  const convertOptimalMatch = () => ({
    ...pick(optimalMatch, ['driver_id', 'driver_type', 'avail_for_dispatch_et', 'tractor_id', 'pta_to_pickup_miles', 'active']),
    avail_location: optimalMatch.predispatch_final_loc
  });

  const fetchDrivers = async (searchKeyword, filters, params) => {
    setRows(null);
    const filterParams = new URLSearchParams(filters).toString();
    const paginationParams = new URLSearchParams(params).toString();
    setLoading(true);
    await instance
      .get(`dispatching/drivers/search/${searchKeyword}?${paginationParams}${filterParams ? `&${filterParams}` : ''}`)
      .then((res) => {
        let { results } = res.data;
        res.data.filter_options.optimal_match_options = optimalMatchOptions;
        setFilterValues(res.data.filter_options);
        const existingOptimalMatch = results.find((result) => result.driver_id === optimalMatch?.driver_id);
        if (existingOptimalMatch) {
          setOptimalMatchRow(existingOptimalMatch);
          results = results.filter((result) => result.driver_id !== optimalMatch?.driver_id);
        } else if (optimalMatch) {
          setOptimalMatchRow(convertOptimalMatch());
        }
        setHasResults(results.length > 0);
        setRows(results);
        setCount(res.data.count);
      })
      .catch((err) => console.error(err))
      .finally(() => { setLoading(false); });
  };

  useEffect(() => {
    const params = {
      load_id: currentLoad?.load_id,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      ordering: sort === 'asc' ? ordering : `-${ordering}`,
      search_session_uid: sessionUid
    };
    let filters = {};
    let dates = {};
    const { keyword: searchTerm, params: searchPanel } = search;
    if (!searchTerm) {
      filters = { pickup_radius: searchPanel?.pickUpRadius };
      dates = { avail_datetime_max: searchPanel?.pickUpStart };
    }

    // Override the Search Panel filters as applicable
    filters = {
      ...filters,
      driver_id: driverIdFilter,
      tractor_id: tractorIdFilter,
      pta_to_pickup_miles: distanceFromPickUpFilter,
      avail_zip: dispatchLocationFilter?.split(', ')[2],
      driver_type: driverTypeFilter,
      dispatch_match_filter: dispatchMatchFilter.replace(/ /g, '').toLowerCase(),
    };
    dates = {
      ...dates,
      avail_datetime_min: availableStartFilter,
      ...(availableEndFilter !== null && { avail_datetime_max: availableEndFilter })
    };

    Object.entries(dates).forEach(([key, date]) => {
      filters[key] = date?.tz(DEFAULT_TIMEZONE)?.format(DATE_TIME_WITH_SEC_T_DIV);
    });
    filters = pickBy(filters, (v) => v === 0 || !!v);

    setSelectedDriver(null);
    debouncedSearchHandler(searchTerm ?? '', filters, params);
    window.scrollTo({ top: 0 });
  }, [
    page,
    rowsPerPage,
    ordering,
    sort,
    search,
    driverIdFilter,
    tractorIdFilter,
    distanceFromPickUpFilter,
    dispatchLocationFilter,
    driverTypeFilter,
    availableStartFilter,
    availableEndFilter,
    dispatchMatchFilter
  ]);

  useEffect(() => {
    setPage(0);
  }, [
    rowsPerPage,
    ordering,
    sort,
    search,
    driverIdFilter,
    tractorIdFilter,
    distanceFromPickUpFilter,
    dispatchLocationFilter,
    driverTypeFilter,
    availableStartFilter,
    availableEndFilter
  ]);

  const handleSortClick = (value) => {
    if (ordering === value) {
      setSort(sort === 'asc' ? 'desc' : 'asc');
    } else {
      setSort('asc');
      setOrdering(value);
    }
  };

  const getAvailforDispatchColumn = (row) => (
    <Box sx={{ display: 'flex', alignItems: 'center', '& .MuiSvgIcon-root': { mr: 1 } }}>
      {(() => {
        if (row.assignment_status === ASSIGNED) {
          return (
            <Link
              component="button"
              variant="default"
              sx={{ display: 'flex', alignItems: 'center' }}
              onClick={() => setSearchParams((prev) => ([...prev.entries(), ['show_history', true]]))}
            >
              <TimelapseSharp />
              {ASSIGNED}
            </Link>
          );
        }
        if (!row.assignable) {
          return (
            <>
              <NotInterestedSharp />
              <Link
                component="button"
                variant="default"
                onClick={() => {
                  setSearchParams({
                    show_exclusions: true,
                    exclusion_type: DRIVER,
                    excluded_item: row?.driver_id
                  });
                }}
              >
                Excluded
              </Link>
            </>
          );
        }
        return (
          <>
            {getSyncTimeDiff(row.avail_for_dispatch_et, dataUpdated, row.avail_timezone, SHORT_DATE_TIME_AT_DIV) === 'Now' && <AlarmOnSharp />}
            {getSyncTimeDiff(row.avail_for_dispatch_et, dataUpdated, row.avail_timezone, SHORT_DATE_TIME_AT_DIV)}
          </>
        );
      })(row)}
    </Box>
  );
  const isDriverPendingSourceRequest = showSourceSuggestions && selectedDriver?.has_source_request;
  const hasSource = showSourceSuggestions && optimalMatch?.has_source;

  const getAssignmentBarContents = () => (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
      <Box
        sx={{
          width: 'auto',
          display: 'flex',
          alignItems: 'center',
          columnGap: '8px',
        }}
      >
        <ODLaneIcon />
        {currentLoad?.load_id}
        <ArrowForward sx={{ mx: 1 }} />
        {selectedDriver?.is_sleeper ? <PeopleSharp /> : <PersonSharp />}
        <DriverIdentifier driver={selectedDriver} />
      </Box>
      <ODButton
        id="assign-driver-search"
        variant="blue"
        startIcon={<CheckCircleSharp />}
        onClick={() => setConfirmAssignmentOpen(true)}
        disabled={checkIsDisabled()}
      >
        Confirm Driver Assignment
      </ODButton>
    </Box>
  );

  return (
    <>
      {!!selectedDriver && (
        <Box
          sx={{
            backgroundColor: 'level1',
            border: '1px solid',
            borderColor: 'level3',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            padding: '8px 16px',
            height: '40px',
            mb: 2
          }}
        >
          {getAssignmentBarContents()}
        </Box>
      )}
      <Box
        sx={{
          overflowY: 'auto',
          position: 'relative',
          height: (rows?.length === 0) ? 'max-content' : `calc(100%${!!selectedDriver ? ' - 73px' : ''})`
        }}
      >
        <ODTable stickyHeader>
          <ODTableHead>
            <ODTableRow>
              {LOAD_HEADERS.map((header) => (
                <ODTableCell key={header.value}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', my: 1, rowGap: 1 }}>
                    <TableSortLabel
                      active={ordering === header.sortKey}
                      hideSortIcon={!header.sort}
                      direction={sort}
                      onClick={() => handleSortClick(header.sortKey)}
                      classes={{ icon: 'sort-icon-lite' }}
                      id={`${header.sortKey}-sorting-Arrow`}
                      sx={{ display: 'flex', flexDirection: 'row' }}
                    >
                      {header.tooltip ? (
                        <ODTooltip title={header.tooltip}>
                          <span>{header.label}</span>
                        </ODTooltip>
                      ) : header.label}
                    </TableSortLabel>
                    {getHeaderFilter({ header, filterValues, searchTerm: search?.keyword })}
                  </Box>
                </ODTableCell>
              ))}
            </ODTableRow>
            {optimalMatchRow && (
              <ODTableRow
                id={`dispatch-anything-optimal-match${optimalMatchRow.driver_id}`}
                sx={{
                  '&.MuiTableRow-root': {
                    '&:hover': {
                      '& .MuiTableCell-root': { backgroundColor: 'level2' }
                    },
                    '&.Mui-selected': {
                      '& .MuiTableCell-root': { backgroundColor: 'colors.lightBlue1' },
                      '&:hover': {
                        '& .MuiTableCell-root': { backgroundColor: 'colors.lightBlue2' }
                      }
                    }
                  },
                  '&.MuiTableRow-root > .MuiTableCell-root': {
                    backgroundColor: 'level1',
                    top: '91px !important',
                  },
                }}
                onClick={() => setSelectedDriver(optimalMatchRow.driver_id === selectedDriver?.driver_id ? null : optimalMatchRow)}
                selected={selectedDriver?.driver_id === optimalMatchRow.driver_id}
              >
                <ODTableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ODRadio
                      sx={{ mr: 1 }}
                      checked={selectedDriver?.driver_id === optimalMatchRow.driver_id}
                      onClick={() => setSelectedDriver(optimalMatchRow.driver_id === selectedDriver?.driver_id ? null : optimalMatchRow)}
                    />
                    <SearchMatchHighlight
                      content={(
                        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
                          <ODTooltip placement="top" title="Optimal Match">
                            <StarsSharp sx={{ color: 'optimalblue.main' }} />
                          </ODTooltip>
                          <Link
                            component="button"
                            variant="default"
                            sx={{ fontWeight: 600 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickDriver(optimalMatchRow);
                            }}
                          >
                            {optimalMatchRow.driver_id}
                          </Link>
                        </Box>
                      )}
                      matchTerm={optimalMatchRow.driver_id}
                      searchTerm={search?.keyword}
                    />
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <SearchMatchHighlight
                    content={optimalMatchRow.tractor_id || '-'}
                    matchTerm={optimalMatchRow.tractor_id || '-'}
                    searchTerm={search?.keyword}
                  />
                </ODTableCell>
                <ODTableCell>
                  {optimalMatchRow.driver_type}
                </ODTableCell>
                <ODTableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
                    <>
                      {getSyncTimeDiff(optimalMatchRow.avail_for_dispatch_et, dataUpdated, optimalMatchRow.avail_timezone, SHORT_DATE_TIME_AT_DIV) === 'Now' && <AlarmOnSharp />}
                      {getSyncTimeDiff(optimalMatchRow.avail_for_dispatch_et, dataUpdated, optimalMatchRow.avail_timezone, SHORT_DATE_TIME_AT_DIV)}
                    </>
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <SearchMatchHighlight
                    content={optimalMatchRow.avail_location || '-'}
                    matchTerm={optimalMatchRow.avail_location || '-'}
                    searchTerm={search?.keyword}
                  />
                </ODTableCell>
                <ODTableCell>
                  {(!!optimalMatchRow.pta_to_pickup_miles || optimalMatchRow.pta_to_pickup_miles === 0) ? formatWithUnit(optimalMatchRow.pta_to_pickup_miles, 'mi') : '-' }
                </ODTableCell>
                <ODTableCell data-col-match-label="Dispatch Match">
                  <DriverDispatchMatchCell row={optimalMatchRow} />
                </ODTableCell>
                <ODTableCell>
                  <Link
                    component="button"
                    variant="default"
                    sx={{ fontWeight: 600 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setPermitsAndRequirementsDriver(optimalMatchRow);
                    }}
                  >
                    View Details
                  </Link>
                </ODTableCell>
              </ODTableRow>
            )}
          </ODTableHead>
          <ODTableBody data-testid="dispatching-drivers-search-table-body">
            {!rows ? (
              <>
                {[...Array(50)].map((__, i) => (
                  <ODTableRow key={`dispatching-search-skeleton-row-${i}`}>
                    {[...Array(8)].map((___, ind) => (
                      <ODTableCell key={`dispatching-search-skeleton-cell-${ind}`}>
                        <Skeleton height="100%" />
                      </ODTableCell>
                    ))}
                  </ODTableRow>
                ))}
              </>
            ) : (rows.map((row) => (
              <ODTableRow
                key={`dispatch-anything-row-${row.driver_id}`}
                id={`dispatch-anything-row-${row.driver_id}`}
                sx={{ '&[disabled]': { backgroundColor: 'colors.neutral0' } }}
                onClick={() => setSelectedDriver(row.driver_id === selectedDriver?.driver_id || !row.assignable ? null : row)}
                selected={selectedDriver?.driver_id === row.driver_id}
                disabled={!row.assignable && (row.assignment_status !== ASSIGNED)}
              >
                <ODTableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ODRadio
                      sx={{ mr: 1 }}
                      checked={selectedDriver?.driver_id === row.driver_id}
                      onClick={() => setSelectedDriver(row.driver_id === selectedDriver?.driver_id ? null : row)}
                    />
                    <SearchMatchHighlight
                      content={(
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {row.is_sleeper ? <PeopleSharp sx={{ mr: 1 }} /> : <PersonSharp sx={{ mr: 1 }} />}
                          <Link
                            component="button"
                            variant="default"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickDriver(row);
                            }}
                          >
                            {row.driver_id}
                          </Link>
                        </Box>
                      )}
                      matchTerm={row.driver_id}
                      searchTerm={search?.keyword}
                    />
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <SearchMatchHighlight
                    content={row.tractor_id || '-'}
                    matchTerm={row.tractor_id || '-'}
                    searchTerm={search?.keyword}
                  />
                </ODTableCell>
                <ODTableCell>
                  {row.driver_type}
                </ODTableCell>
                <ODTableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
                    {getAvailforDispatchColumn(row)}
                  </Box>
                </ODTableCell>
                <ODTableCell>
                  <SearchMatchHighlight
                    content={row.avail_location || '-'}
                    matchTerm={row.avail_location || '-'}
                    searchTerm={search?.keyword}
                  />
                </ODTableCell>
                <ODTableCell>
                  <Box>{(!!row.pta_to_pickup_miles || row.pta_to_pickup_miles === 0) ? formatWithUnit(row.pta_to_pickup_miles, 'mi') : '-' }</Box>
                </ODTableCell>
                <ODTableCell data-col-match-label="Dispatch Match">
                  <DriverDispatchMatchCell row={row} />
                </ODTableCell>
                <ODTableCell>
                  {!row.assignable
                    ? '-'
                    : (
                      <Link
                        component="button"
                        variant="default"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPermitsAndRequirementsDriver(row);
                        }}
                      >
                        View Details
                      </Link>
                    )}
                </ODTableCell>
              </ODTableRow>
            )))}
          </ODTableBody>
        </ODTable>
      </Box>
      <PermitsAndRequirementsDialog
        open={!!permitsAndRequirementsDriver}
        permitRequired={permitsAndRequirementsDriver?.req_permits}
        permitDriver={currentLoad?.driver?.permits}
        equipment={permitsAndRequirementsDriver?.equipment}
        onClose={() => setPermitsAndRequirementsDriver(null)}
        description={() => dynamicDescription(permitsAndRequirementsDriver, currentLoad)}
      />
      {confirmAssignmentOpen && (
        <ConfirmSearchAssignment
          subject={DRIVER}
          assignment={isDriverPendingSourceRequest || hasSource ? dynamicSourceBrokerageDescription([selectedDriver], currentLoad, DRIVER) : dynamicDescription([selectedDriver], currentLoad, DRIVER)}
          isOptimal={selectedDriver?.driver_id === optimalMatch?.driver_id}
          hasOptimal={!!optimalMatch}
          optimalMatchNode={optimalMatchNode}
          onCancel={() => setConfirmAssignmentOpen(false)}
          hasSource={hasSource}
          isDriverPendingSourceRequest={isDriverPendingSourceRequest}
          onConfirm={async (isOptimal, reasons, explanation) => {
            // delete existing source request if applicable
            if (showSourceSuggestions && isDriverPendingSourceRequest) {
              await instance.delete(
                `/dispatching/drivers/${selectedDriver?.driver_id}/source-request/`
              ).catch((response) => console.error(response.data.errors));
            }
            if (isOptimal) {
              confirmAssignment(
                currentLoad.engine_run_id,
                selectedDriver.driver_id,
                currentLoad.load_id,
                optimalMatch.overall_rating,
                optimalMatch.movement_option_id
              );
            } else {
              instance.post(
                '/dispatching/assignment-feedback/',
                {
                  movement_option_id: optimalMatch?.movement_option_id ?? null,
                  engine_run: currentLoad.engine_run_id,
                  assignment_load_id: currentLoad.load_id,
                  assignment_driver_id: selectedDriver.driver_id,
                  search_query: search?.keyword,
                  reasons_categories: reasons,
                  form_text: explanation
                }
              ).catch((err) => console.error(err));
              confirmAssignment(currentLoad.engine_run_id, selectedDriver.driver_id, currentLoad.load_id);
            }

            resetValues();
            onClean();
          }}
        />
      )}
    </>
  );
};

export default DriversSearch;
