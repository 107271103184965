import React from 'react';
import StarsSharp from '@mui/icons-material/StarsSharp';
import StarSharp from '@mui/icons-material/StarSharp';
import StarHalfSharp from '@mui/icons-material/StarHalfSharp';
import StarOutlineSharp from '@mui/icons-material/StarOutlineSharp';
import instance from '../../../utils/axios_instance';

export const confirmAssignment = async (engineRunId, driverId, loadId, rating = null, movementOptionId = null) => {
  if (!engineRunId || !loadId || !driverId) return;
  const submission = {
    engine_run_id: engineRunId,
    assignments: [
      {
        driver_id: driverId,
        load_id: loadId,
        ...(movementOptionId && { movement_option_id: movementOptionId }),
        ...(rating && { overall_rating: rating })
      }
    ]
  };
  const response = await instance.post('/dispatching/assignments/', submission);
  return response;
};

export const getOriginLabel = (origLiveFlag, isSource = false) => {
  if (isSource) return 'Source Pick Up';
  return origLiveFlag ? 'Origin Live Load' : 'Origin PreLoad Pick Up';
};

export const getDestinationLabel = (destDropFlag, isSource = false) => {
  if (isSource) return 'Source Drop Off';
  return destDropFlag ? 'Destination PreLoad Drop Off' : 'Destination Live Unload';
};

export const getStarLabel = (overall, secondary) => {
  const rating = parseInt(overall, 10);
  switch (true) {
    case rating === 5:
      if (!!secondary && secondary > 1) {
        return { star: <StarSharp />, label: 'Great' };
      }
      return { star: <StarsSharp sx={{ color: 'optimalblue.main' }} />, label: 'Optimal' };
    case rating >= 3:
      return { star: <StarHalfSharp />, label: 'Good' };
    default:
      return { star: <StarOutlineSharp />, label: 'Feasible' };
  }
};
