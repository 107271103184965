import React, { useState } from 'react';
import { useTheme, Box, Autocomplete, InputLabel, TextField } from '@mui/material';
import LocationOnSharp from '@mui/icons-material/LocationOnSharp';
import ExpandLessSharp from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';
import CloseSharp from '@mui/icons-material/CloseSharp';
import { ODCheckbox } from '@OptimalDynamics/core-ai-common-ui';

const AutoCompleteBox = (props) => {
  const {
    autoCompleteList = [],
    title = 'Title',
    style,
    placeholder,
    handleChange,
    value = [],
    onClear,
    optionLabel = 'title',
    startAdornment = null,
    multiple = true,
    disableCloseOnSelect = true,
    onInputChange = null,
    optionLabelFunc = (op) => op[optionLabel],
    ...rest
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [showTitle] = useState(true);
  const theme = useTheme();
  const [input, setInput] = useState('');

  const renderOptions = (renderProps, option, { selected }) => (
    <li
      key={option.id}
      style={{
        fontSize: '14px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        columnGap: '16px',
        backgroundColor: selected ? theme.palette.level3 : ''
      }}
      {...renderProps}
    >
      {multiple && <ODCheckbox checked={selected} />}
      {optionLabelFunc(option, optionLabel)}
    </li>
  );

  const handleInputChange = (evt, val, reason) => {
    // prevents MUI Autocomplete from resetting input when an option is selected
    if ((!evt || evt.type === 'click') && reason === 'reset') return;
    setInput(val);
    onInputChange && onInputChange(val);
  };

  const expandProps = (color = 'text.primary') => ({
    onClick: () => setIsOpen(!isOpen),
    sx: { color, padding: 0 }
  });

  return (
    <div style={{ position: 'relative' }}>
      {showTitle && <InputLabel>{title}</InputLabel>}
      <Autocomplete
        open={isOpen}
        multiple={multiple}
        onChange={(event, newValue) => handleChange(newValue)}
        onInputChange={handleInputChange}
        inputValue={input}
        value={value}
        limitTags={1}
        id={`${title.replace(/\s+/g, '')}-autocomplete-box`}
        options={autoCompleteList}
        disableCloseOnSelect={disableCloseOnSelect}
        disableListWrap
        disablePortal={true}
        isOptionEqualToValue={(option, selectedValue) => option[optionLabel] === selectedValue[optionLabel]}
        getOptionLabel={(option) => optionLabelFunc(option, optionLabel)}
        renderOption={renderOptions}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        ListboxProps={{
          sx: { padding: 0, maxHeight: '180px', backgroundColor: 'level2' },
        }}
        renderInput={(params) => {
          let displayValue = placeholder;
          if (multiple && value?.length > 0) displayValue = `(${value.length}) Selected`;
          if (!multiple && !!value) displayValue = value[optionLabel];

          const color = displayValue !== placeholder ? 'text.primary' : 'text.unselected';

          return (
            <TextField
              {...params}
              variant="standard"
              sx={{
                backgroundColor: isOpen ? 'level2' : 'level1',
                ...style,
              }}
              placeholder={displayValue}
              InputProps={{
                ...params.InputProps,
                sx: {
                  input: {
                    fontSize: '14px',
                    '::placeholder': { color, opacity: 1 }
                  },
                  '&.MuiInput-root': { pl: startAdornment === false && '16px', pr: '16px !important' }
                },
                startAdornment: startAdornment === false ? null : startAdornment || <LocationOnSharp style={{ padding: '16px' }} />,
                endAdornment: (
                  <Box sx={{ display: 'flex', columnGap: '16px', svg: { cursor: 'pointer' } }}>
                    {multiple && value.length > 0 && (
                      <CloseSharp
                        sx={{ color }}
                        onClick={onClear}
                      />
                    )}
                    {isOpen ? <ExpandLessSharp {...expandProps()} /> : <ExpandMoreSharp {...expandProps(color)} />}
                  </Box>
                )
              }}
            />              
          );
        }}
        {...rest}
      />
    </div>
  );
};
export default AutoCompleteBox;
