import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import {
  ODTableHead,
  ODTableRow,
  ODAvatar,
  ODTableBody,
  ODLaneIcon,
  ODTableCell,
  ODTableContainer
} from '@OptimalDynamics/core-ai-common-ui';
import { Box, Link, Table, TableSortLabel, useTheme } from '@mui/material';
import ViewListSharp from '@mui/icons-material/ViewListSharp';
import ShuffleSharp from '@mui/icons-material/ShuffleSharp';
import { useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import useColumnData from '../helpers/useColumnData';
import { SHORT_DATE_TIME, compareTimeToNow, getLocalizedTimeRange } from '../../../utils/datetimes';
import { SettingsContext } from '../../../utils/context';
import { DETAILS, MATCH_DETAILS } from '../../../AppConstants';
import { CopyClick } from '../../../common/CopyClick';
import { DispatchingLoad, DispatchingLoadsTableProps } from '../types';
import { BROKERAGE, MATCHES, RELAXED } from '../helpers/constants';
import { centsAbbreviate } from '../TabsGraphAccordion';
import LoadDispatchMatchCell from './LoadDispatchMatchCell';
import { UpdateUserSettings } from '../../../store/actions';
import { RootState } from '../../../store/reducers';

const DispatchingLoadsTable = ({
  headers,
  loads = [],
  onSelectRow,
  ordering,
  setOrdering,
  selectedLoadId,
  onClickLoad,
  isSelected, 
  appliedFilterCount
}: DispatchingLoadsTableProps) => {
  const userSettings = useSelector((state: RootState) => state.userSettings);
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setShowSettings } = useContext(SettingsContext);
  const [rows, setRows] = useState(loads);
  const [lastDetailColumn, setLastDetailColumn] = useState<number>(0);
  const {
    showRelaxedMatches,
    orgUseRelaxedDispatching,
    assignBrokerageLoads,
    emptyTableTextWhenFilterApplied
  } = useFlags();
  const dispatch = useDispatch();

  const { checkColumn } = useColumnData(headers);

  useEffect(() => {
    setRows(loads);
  }, [loads]);

  useEffect(() => {
    setLastDetailColumn(headers.filter((header) => header.visible && header.parent === DETAILS).length);
  }, [headers]);

  const handleLoadIdClick = (event: SyntheticEvent, row: DispatchingLoad) => {
    event.stopPropagation();
    onClickLoad(row);
  };

  const handleSortClick = (value: string) => {
    if (ordering.includes(value)) {
      const newOrdering = ordering.startsWith('-') ? ordering.substring(1) : `-${ordering}`;
      setOrdering(newOrdering);
      dispatch(UpdateUserSettings({ ...userSettings, dispatchingLoadsSorting: newOrdering }));
    } else {
      setOrdering(value);
      dispatch(UpdateUserSettings({ ...userSettings, dispatchingLoadsSorting: value }));
    }
  };

  const handleRowClick = (row: DispatchingLoad) => {
    let slideout_view_type = MATCHES;
    if (orgUseRelaxedDispatching && showRelaxedMatches && row.is_relaxed) {
      slideout_view_type = RELAXED;
    }
    if (row.assigned) {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        show_history: 'true'
      });
      return;
    }
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      slideout_view_type
    });
    onSelectRow(row.load_id);
  };

  const getColSpan = (group: string) => headers.filter((header) => header.visible && header.parent === group).length;

  const getTableCells = (column: string, data: DispatchingLoad) => {
    if (!checkColumn(column)) return;
    const { avail_for_dispatch_et } = data;
    const pta = compareTimeToNow(avail_for_dispatch_et);
    switch (column) {
      case 'Load ID':
        return (
          <ODTableCell
            data-col-match-label="Load ID"
            onClick={(event: SyntheticEvent) => handleLoadIdClick(event, data)}
            sx={{ '&:hover': { cursor: 'pointer', backgroundColor: `${theme.palette.level3} !important` } }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {assignBrokerageLoads && data.internal_source === BROKERAGE ? <ShuffleSharp /> : <ODLaneIcon />}
              <CopyClick copyText={data.load_id}>
                <Link component="button" variant="default" onClick={(event: SyntheticEvent) => handleLoadIdClick(event, data)}>
                  {data.load_id}
                </Link>
              </CopyClick>
            </div>
          </ODTableCell>
        );
      case 'TMS Assignment':
        if (!assignBrokerageLoads) return;
        return (
          <ODTableCell data-col-match-label="TMS Assignment">
            {!data.internal_source ? '-' : capitalize(data.internal_source)}
          </ODTableCell>
        );
      case 'Shipper ID':
        return (
          <ODTableCell data-col-match-label="Shipper ID">
            {data.shipper_id}
          </ODTableCell>
        );
      case 'Shipper Name':
        return (
          <ODTableCell data-col-match-label="Shipper Name">
            {data.shipper_name}
          </ODTableCell>  
        );
      case 'Pick Up Location':
        return (
          <ODTableCell data-col-match-label="Pick Up Location">
            {data.pickup_location}
          </ODTableCell>
        );
      case 'Pick Up Window':
        return (
          <ODTableCell data-col-match-label="Pick Up Window">
            {getLocalizedTimeRange(data.pickup_start_et, data.pickup_end_et, data.pickup_timezone, SHORT_DATE_TIME) || '-'}
          </ODTableCell>
        );
      case 'Origin Type':
        return (
          <ODTableCell data-col-match-label="Origin Type">
            {!!data.orig_live_flag ? 'Live Load' : 'PreLoad'}
          </ODTableCell>
        );
      case 'Drop Off Location':
        return (
          <ODTableCell data-col-match-label="Drop Off Location">
            {data.dropoff_location}
          </ODTableCell>
        );
      case 'Drop Off Window':
        return (
          <ODTableCell data-col-match-label="Drop Off Window">
            {getLocalizedTimeRange(data.dropoff_start_et, data.dropoff_end_et, data.dropoff_timezone, SHORT_DATE_TIME) || '-'}
          </ODTableCell>
        );
      case 'Destination Type':
        return (
          <ODTableCell data-col-match-label="Destination Type">
            {!!data.dest_drop_flag ? 'PreLoad' : 'Live Unload'}
          </ODTableCell>
        );
      case 'Loaded Mileage':
        return (
          <ODTableCell data-col-match-label="Loaded Mileage">
            {data.loaded_mileage ? `${data.loaded_mileage}mi` : '-'}
          </ODTableCell>
        );
      case 'Revenue':
        return (
          <ODTableCell data-col-match-label="Revenue">
            {centsAbbreviate(Number(data.revenue))}
          </ODTableCell>
        );
      case 'TMS Pickup Region':
        return (
          <ODTableCell data-col-match-label="TMS Pickup Region">
            {data.pickup_region_code}
          </ODTableCell>
        );
      case 'TMS Dropoff Region':
        return (
          <ODTableCell data-col-match-label="TMS Dropoff Region">
            {data.dropoff_region_code}
          </ODTableCell>
        );
      case 'Match Type':
        return (
          <ODTableCell data-col-match-label="Match Type">
            <LoadDispatchMatchCell row={data} pta={pta} />
          </ODTableCell>
        );
      case 'Match Action':
        return (
          <ODTableCell data-col-match-label="Match Action">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link component="button" variant="blue">
                {(!data.assigned && !!data.driver.driver_id) ? 'View Details' : 'Review'}
              </Link>
            </div>
          </ODTableCell>
        );
      default:
        return null;
    }
  };

  const emptyTableDataMessage =   
  emptyTableTextWhenFilterApplied
    ? appliedFilterCount > 0
      ? 'No data was found for applied filters. Please review and adjust them as needed.'
      : 'There are no results to display.'
    : 'There are no results to display.';

  return (
    <ODTableContainer
      stickyFirst
      stickyLast
      sx={{ overflow: 'initial', '&.MuiPaper-root': { overflow: 'initial' } }}
      id="dispatching-loads-table-container"
    >
      <Table stickyHeader>
        <ODTableHead>
          <ODTableRow>
            <ODTableCell colSpan={getColSpan(DETAILS)}>Load Details</ODTableCell>
            <ODTableCell
              colSpan={getColSpan(MATCH_DETAILS) + 1}
              sx={{ pl: 2, borderLeft: `1px solid ${theme.palette.level3}` }}
            >
              Match Details
            </ODTableCell>
          </ODTableRow>
          <ODTableRow
            sx={{
              [`& .MuiTableCell-root:nth-of-type(${lastDetailColumn + 1})`]: {
                borderLeft: `1px solid ${theme.palette.level3}`, pl: 2
              }
            }}
          >
            {headers.map((header) => (
              <>
                {checkColumn(header.label) && (
                  <ODTableCell key={`dispatching-loads-header-${header.label}`}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TableSortLabel
                        active={ordering.includes(header.value)}
                        hideSortIcon={!header.sortable}
                        direction={ordering.startsWith('-') ? 'desc' : 'asc'}
                        onClick={() => { if (header.sortable) handleSortClick(header.value); }}
                        classes={{ icon: 'sort-icon-lite' }}
                        id={`${header.value}-sorting-Arrow`}
                      >
                        {header.label}
                      </TableSortLabel>
                    </Box>
                  </ODTableCell>
                )}
              </>
            ))}
            <ODTableCell sx={{ maxWidth: 40, padding: 0 }}>
              <ODAvatar variant="transparent" sx={{ m: 0.5 }} size="small" onClick={() => setShowSettings(true)}>
                <ViewListSharp />
              </ODAvatar>
            </ODTableCell>  
          </ODTableRow>
        </ODTableHead>
        <ODTableBody
          data-testid="dispatching-loads-table-body"
          sx={{
            [`& .MuiTableCell-root:nth-of-type(${lastDetailColumn + 1})`]: {
              borderLeft: `1px solid ${theme.palette.level3}`, pl: 2
            }
          }}
        >
          {!!rows.length ? (
            <>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row);
                return (    
                  <ODTableRow
                    key={`dispatching-loads-row-${row.load_id}`}
                    id={`dispatching-loads-table-row-${index}`} 
                    selected={isItemSelected || selectedLoadId === row.load_id}
                    onClick={() => handleRowClick(row)}
                    sx={{ '&:hover': { cursor: 'pointer', backgroundColor: 'level2' } }}
                  >
                    {headers.map((header) => getTableCells(header.label, row))}
                    <ODTableCell width="32px" />
                  </ODTableRow>
                ); 
              })}
            </>
          ) : (
            <ODTableRow sx={{ '&:hover': { background: 'inherit' } }}>
              <ODTableCell colSpan={headers.filter((header) => header.visible).length + 1} sx={{ textAlign: 'center', borderBottom: 'none' }}>
                {emptyTableDataMessage}
              </ODTableCell>
            </ODTableRow>
          )}
        </ODTableBody>
      </Table>
    </ODTableContainer>
  );
};

export default DispatchingLoadsTable;
