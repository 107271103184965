import React, { FC, ReactElement } from 'react';
import { Box, Link, SxProps, Typography } from '@mui/material';

interface SlideoutLineItemProps {
  title: string;
  content: string;
  contentCallback?: () => void;
  multiline?: boolean;
  icon?: ReactElement;
  sx?: SxProps;
}

const SlideoutLineItem: FC<SlideoutLineItemProps> = ({ title, content, contentCallback, multiline = false, icon, sx }) => (
  <Box
    sx={{
      borderTop: 1,
      borderTopColor: 'level3',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: '10px 16px',
      ...(!multiline ? { display: 'flex' } : {}),
      ...sx
    }}
  >
    <Typography sx={{ alignSelf: 'start', display: 'flex', alignItems: 'center' }}>
      <Box sx={{ mr: '8px', display: 'flex', alignItems: 'center' }}>
        {icon && icon} 
      </Box>
      <strong> 
        {title}
      </strong>
    </Typography>
    {multiline ? (
      <Box>{content}</Box>
    ) : (
      <Typography sx={{ maxWidth: '50%', textAlign: 'right', display: 'flex', alignItems: 'center' }}>
        <Link
          sx={{ ml: '2px' }}
          component="button"
          variant={!contentCallback ? 'disabled' : 'default'}
          onClick={contentCallback}
          disabled={!contentCallback}
        >
          {content || '-'}
        </Link>
      </Typography>
    )}
  </Box>
);

export default SlideoutLineItem;
