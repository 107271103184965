import React, { useState, ChangeEvent } from 'react';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, InputLabel, Typography, InputAdornment } from '@mui/material';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import ConnectWithoutContactSharpIcon from '@mui/icons-material/ConnectWithoutContactSharp';
import EditRoadSharp from '@mui/icons-material/EditRoadSharp';
import EditSharp from '@mui/icons-material/EditSharp';
import HourglassTopSharp from '@mui/icons-material/HourglassTopSharp';
import InsightsSharp from '@mui/icons-material/InsightsSharp';
import SearchSharp from '@mui/icons-material/SearchSharp';
import { ODMenu, ODLaneIcon, ODInput, ODSlideOut } from '@OptimalDynamics/core-ai-common-ui';
import { DEFAULT_TIMEZONE } from '../../../utils/datetimes';
import instance from '../../../utils/axios_instance';
import { ConfirmContainer } from '../../../common';
import { DO_NOT_LINK, DRIVER_HOLD, EMPTY_TO_HOME, SOURCE, CUSTOM_SOURCE_REQUEST, REQUESTED, READY, SOURCING } from '../helpers/constants';
import { LoadDetails } from '../helpers/dynamicDescription';
import { DetailCardTitle, DetailCardAccordion } from '../DetailCard';
import { IconWrapper } from '../DetailCard/DetailCardAccordion';
import { mapSourceSearchToLoadOption } from '../helpers/mappingSourceToLoadObject';
import { DetailCardIdentifier, DispatchingDriver, SourceSearch } from '../types';
import { LoadsSlideoutCardTimeline } from './LoadsSlideoutCardTimeline';

interface SourceLoadDetailCardProps {
  searchSuggestion?: SourceSearch;
  driver: DispatchingDriver;
  triggerRefresh?: () => void;
}

export const SOURCE_STATUS_SPECIFICS = {
  [REQUESTED]: { label: 'Requested', icon: <HourglassTopSharp />, color: 'colors.neutral6', text: 'The ‘Requested’ status is the default state once a source request has been sent to ‘Needs Sourcing.’ This should be maintained until you are actively sourcing.' },
  [SOURCING]: { label: 'Sourcing', icon: <SearchSharp />, color: 'optimalblue.main', text: 'The ‘Sourcing’ status should be used to inform your team that you are actively sourcing a load, so that no overlap occurs if multiple users are sourcing.' },
  [READY]: { label: 'Ready', icon: <CheckCircleSharp />, color: 'success.main', text: 'Select the ‘Ready’ status once a load is successfully sourced and available in your internal loads. If true, enter your load ID in the status header once selected.' },
};

const SourceLoadDetailCard = ({ searchSuggestion, driver, triggerRefresh }: SourceLoadDetailCardProps) => {
  const { showSourceSuggestions, tentativeNextSteps, showShipperName, useSourceWorkflowEnhancements } = useFlags();
  const [isReady, setReady] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [readiedLoadId, setReadiedLoadId] = useState<string | undefined>(undefined);
  
  if (!searchSuggestion) return <></>;

  const changeStatus = (next: string, sourced_load_id?: string) => {
    instance.patch(`dispatching/drivers/${driver?.driver?.driver_id}/source-request/`, { sourcing_status: next, sourced_load_id })
      .then(() => { if (!!triggerRefresh) triggerRefresh(); })
      .catch((err) => console.error(err));
  };

  const sourceLoad = mapSourceSearchToLoadOption(searchSuggestion);

  const usingSourceWorkflow = useSourceWorkflowEnhancements && !!driver.has_source_request;

  const validLoadId = !!readiedLoadId && readiedLoadId.length < 51;

  // @ts-expect-error TS objects to using a string as an index key
  const status = SOURCE_STATUS_SPECIFICS[driver.sourcing_status];
  const description = driver.sourcing_status === READY ? driver.sourced_load_id : status?.label;

  return (
    <DetailCardTitle
      key="driver-source-request"
      id="driver-event-source-request"
      rating={undefined}
      isRelaxed={false}
      hasSourceRequest={true}
      isCurrentRow={true}
      isSource={true}
      title={`${searchSuggestion.movement_type === CUSTOM_SOURCE_REQUEST ? 'Manual ' : ''}Source Request Details`}
      sourceHours={!!sourceLoad ? moment.tz(sourceLoad?.source_pickup, DEFAULT_TIMEZONE).diff(moment(), 'hours') : undefined}
      active={true}
      selected={true}
      location={SOURCE}
    >
      <DetailCardAccordion
        key="driver-source-accordion"
        id="driver-event-source-accordion"
        identifier={{ 
          label: !!driver.has_source_request ? (useSourceWorkflowEnhancements ? 'STATUS' : 'Source Request') : 'Source Load',
          description: usingSourceWorkflow ? description : '',
          icon: (
            !!driver.has_source_request ? (
              <IconWrapper fullColor color={usingSourceWorkflow ? status.color : 'colors.neutral6'}>
                {useSourceWorkflowEnhancements ? status.icon : <ConnectWithoutContactSharpIcon />}
              </IconWrapper>
            ) : <SearchSharp />
          )
        }}
        type={DO_NOT_LINK}
        selected={true}
        sourceSearch={driver}
        onClickInfo={useSourceWorkflowEnhancements && (() => setShowInfo(true))}
        menu={usingSourceWorkflow && (
          <ODMenu
            id="source-status-edit-menu"
            menuIcon={<EditSharp />}
            menuOptions={
              Object.entries(SOURCE_STATUS_SPECIFICS).map(([key, details]) => ({
                label: details.label,
                icon: details.icon,
                onClick: () => {
                  if (key === READY) setReady(true);
                  else changeStatus(key);
                }
              }))
            }
          />
        )}
      >
        <LoadsSlideoutCardTimeline
          currentDriver={driver}
          loadOption={sourceLoad}
          selected={true}
          showAvailable={true}
        />
      
        {tentativeNextSteps && sourceLoad?.option?.map((load, j) => {
          if (j === 0) return; // First load option is the main source suggestion above
          const isSource = showSourceSuggestions && load.has_source;
          if (!load?.is_tentative) return;
          const identifier: DetailCardIdentifier = {
            icon: <InsightsSharp />,
            label: '',
            description: null
          };

          if (load.movement_type === DRIVER_HOLD) {
            identifier.label = 'Wait For Loads';
          } else if (load.movement_type === EMPTY_TO_HOME) {
            identifier.label = isSource ? 'Source Event' : 'Event';
          } else {
            identifier.label = isSource ? 'Source Load' : `Load: ${load?.load_id}`;
            if (!isSource) {
              identifier.icon = <ODLaneIcon />;
              identifier.description = showShipperName ? <LoadDetails load={load} /> : load.shipper_id;
            }
          }

          return (
            <DetailCardAccordion
              sx={{ margin: 0, marginTop: '20px' }}
              key={`subload-${j}`}
              id={`assign-drivers-slideout-sub-recommendation-${j}`}
              identifier={identifier}
              type={DO_NOT_LINK}
              selected={false}
              defaultCollapse={true}
              isTentative={true}
              sourceSearch={driver}
            >
              <LoadsSlideoutCardTimeline
                currentDriver={driver}
                loadOption={load}
                selected={false}
                showAvailable={false}
              />
            </DetailCardAccordion>
          );
        })}
      </DetailCardAccordion>
      <ConfirmContainer
        open={isReady}
        title="Source Request Ready"
        source="source-ready"
        onAction={(doIt) => {
          if (doIt) changeStatus(READY, readiedLoadId);
          setReady(false);
        }}
        disableConfirm={!validLoadId}
      >
        <Typography sx={{ mb: 2 }}>
          You have selected the &lsquo;Ready&rsquo; status, indicating that the original request has been successfully sourced and is now available in your internal loads. If this is correct, enter the load ID below and confirm to activate the &lsquo;Ready&rsquo; status.
        </Typography>
        <InputLabel>Enter Load ID</InputLabel>
        <ODInput
          id="source-ready-load-id"
          value={readiedLoadId}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setReadiedLoadId(event.target.value)}
          placeholder="Load ID Needed"
          endAdornment={(
            <InputAdornment position="end" sx={{ mr: '4px', columnGap: 2, color: 'text.primary' }}>
              {!validLoadId ? <EditRoadSharp sx={{ color: 'colors.neutral6' }} /> : <CheckCircleSharp sx={{ color: 'success.main' }} />}
            </InputAdornment>
          )}
          disableUnderline={true}
        />
      </ConfirmContainer>
      <ODSlideOut
        title="Source Request Status"
        open={showInfo}
        onClose={() => setShowInfo(false)}
        sx={{ zIndex: 1600 }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography>
            Below are definitions of source request statuses you can adjust to keep your team informed:
          </Typography>
          {Object.values(SOURCE_STATUS_SPECIFICS).map(({ icon, label, text }) => (
            <Box key={`describe-${label}`} sx={{ p: 2, borderRadius: '4px', borderColor: 'level3', borderStyle: 'solid', borderWidth: '1px' }}>
              <Box sx={{ display: 'grid', height: '32px', gridTemplateColumns: '32px auto', mb: 2, gap: 2, alignContent: 'center', svg: { justifySelf: 'center' } }}>
                {icon}
                <strong>{label}</strong>
              </Box>
              <Typography>{text}</Typography>
            </Box>
          ))}
        </Box>
      </ODSlideOut>
    </DetailCardTitle>
  );
};

export default SourceLoadDetailCard;
