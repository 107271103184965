import merge from 'lodash/merge';
import camelCase from 'lodash/camelCase';
import instance from '../../utils/axios_instance';
import { SET_CONFIGS } from '../constants/actionTypes';

interface ConfigObject {
  param: string;
  config: boolean | number | string;
}

interface Action {
  type: string;
  payload?: Record<string, boolean | number | string>;
}

type DispatchType = (action: Action) => void;

const stringToObject = (str: string, value: boolean | number | string) => {
  const path = str.split('.');
  const result: Record<string, any> = {};
  let current = result;

  for (let i = 0; i < path.length - 1; i++) {
    const key = camelCase(path[i]);
    current[key] = current[key] || {};
    current = current[key];
  }

  current[camelCase(path[path.length - 1])] = value;
  return result;
};

interface GetConfigsResponse {
  data: {
    data: ConfigObject[];
    errors: string[];
    success: boolean;
  };
  status: number;
  statusText: string;
}

export const FetchAllOrgExecuteConfigs = () => async (dispatch: DispatchType) => {
  await instance
    .get('/configstore/get_configs/')
    .then((res: GetConfigsResponse) => {
      const executeConfigs = res.data.data.filter((config) => config.param.startsWith('execute'));
      const payload: Record<string, boolean | string | number> = {};
      executeConfigs.forEach((config) => {
        merge(payload, stringToObject(config.param.replace('execute.', ''), config.config));
      });
      dispatch(SetConfigs(payload));
    })
    .catch((err: any) => console.warn(err));
};

export const SetConfigs = (configs: Record<string, string | number| boolean>) => ({
  type: SET_CONFIGS,
  configs,
});
