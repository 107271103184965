import React, { ReactElement, useEffect, useState, useContext, useMemo, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment, { Moment } from 'moment-timezone';
import { Box, Dialog, Drawer, Link, Typography, InputAdornment, Avatar, CSSObject, styled, SelectChangeEvent, InputLabel, Divider } from '@mui/material';
import AddSharp from '@mui/icons-material/AddSharp';
import AttachMoneySharp from '@mui/icons-material/AttachMoneySharp';
import ExpandLessSharp from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import KeyboardArrowLeftSharp from '@mui/icons-material/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharp from '@mui/icons-material/KeyboardArrowRightSharp';
import ManageSearchSharp from '@mui/icons-material/ManageSearchSharp';
import PersonSharp from '@mui/icons-material/PersonSharp';
import PersonSearchSharp from '@mui/icons-material/PersonSearchSharp';
import RemoveSharp from '@mui/icons-material/RemoveSharp';
import SearchSharp from '@mui/icons-material/SearchSharp';
import TuneSharp from '@mui/icons-material/TuneSharp';
import { ODInput, ODNumberInput, ODButton, ODCloseButton, ODLaneSearchIcon, ODLaneIcon, ODToggle, ODTablePagination, ODAvatar, ODSelect } from '@OptimalDynamics/core-ai-common-ui';
import { AMERICAN_DATE_TIME, SHORT_DATE_TIME, DATE_TIME_WITH_SEC_SPACE_DIV, DEFAULT_TIMEZONE } from '../../../utils/datetimes';
import { SearchContext } from '../../../utils/context/searchContext';
import { SessionContext } from '../../../utils/context/sessionCtx';
import { FORM_VARIANT, ODDateTimePicker } from '../../../common/ODDatePicker';
import CitySearch from '../../../common/CitySearch';
import ODTabs from '../../../common/ODTabs';
import SelectedOptions from '../../../common/SelectedOptions';
import { DispatchingDriver, DispatchingLoad, SourceSearch, CityState, DriverEvent } from '../types';
import ODLoaderLabeled from '../components/ODLoader';
import LoadsSearchExternal from '../drivers/LoadsSearchExternal';
import { BY_ID, BY_PARAMS, DRIVER, EXTERNAL, INTERNAL, LOAD, EQUIPMENT_TYPES } from '../helpers/constants';
import { centsAbbreviate } from '../TabsGraphAccordion';
import SourceLoadDetailCard from '../drivers/SourceLoadDetailCard';
import ODNumberInputDollars from '../../../common/ODNumberInputDollars';
import { RootState } from '../../../store/reducers';
import { UpdateUserSettings } from '../../../store/actions';
import DateTimeSpan from './DateTimeSpan';
import { NextEvent } from './NextEventDialog';

const DEFAULT_RADIUS = 200;
const MAXIMUM_RADIUS = 1000;
const MIN_MAX_RADIUS = 350;
const MILE_RADIUS_INCREMENT = 25;

const SUBJECT_SPECIFICS = {
  [DRIVER]: {
    modalTitle: 'Search For Drivers',
    searchIcon: <PersonSearchSharp />,
    resultsTitle: 'Driver Results',
    resultsIcon: <PersonSharp />,
    idSearchPrompt: 'Search By Driver',
    paramsSearchPrompt: 'Search By Availability',
    loading: 'Drivers Being Searched...',
    adjustHeading: 'Adjust Driver Search'
  },
  [LOAD]: {
    modalTitle: 'Search For Loads',
    searchIcon: <ODLaneSearchIcon />,
    resultsTitle: 'Load Results',
    resultsIcon: <ODLaneIcon />,
    idSearchPrompt: 'Search By Load ID',
    paramsSearchPrompt: 'Search By Lane',
    loading: 'Loads Being Searched...',
    adjustHeading: 'Adjust Load Search'
  }
};

const isRadiusInvalid = (value: number, max: number) => (value < 0 || value > max);

const openedAvatarMixin = (): CSSObject => ({
  right: 0,
  borderRadius: '4px 1px 1px 4px',
});

const closedAvatarMixin = (): CSSObject => ({
  left: '72px',
  top: '24px',
  position: 'fixed',
  borderRadius: '1px 4px 4px 1px',
});

const ToggleButton = styled(Avatar, { shouldForwardProp: (prop) => prop !== 'open' })<{ open: boolean }>(
  ({ theme, open }) => ({
    width: '16px',
    height: '40px',
    position: 'relative',
    top: 0,
    zIndex: 1300,
    backgroundColor: theme.palette.level3,
    cursor: 'pointer',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...(open && {
      ...openedAvatarMixin(),
      '& .MuiAvatar-root': openedAvatarMixin(),
    }),
    ...(!open && {
      ...closedAvatarMixin(),
      '& .MuiAvatar-root': closedAvatarMixin(),
    }),
  }),
);

interface RadiusInputProps {
  prompt: string;
  id: string;
  value: number;
  setValue: (_newValue: number) => void;
}

const RadiusInput = ({ prompt, id, value, setValue }: RadiusInputProps) => {
  const { noExternalLoadsLimit } = useFlags();
  const maxRadius = noExternalLoadsLimit ? MIN_MAX_RADIUS : MAXIMUM_RADIUS;

  return (
    <ODNumberInput
      id={`${id}-radius`}
      prompt={prompt}
      maximum={maxRadius}
      increment={MILE_RADIUS_INCREMENT}
      value={value}
      setValue={(n) => setValue(n || 0)}
      errored={isRadiusInvalid(value, maxRadius)}
      errorMessage={`Radius must be between 0 and ${maxRadius}`}
    />
  );
};

interface SubheadingAccordionProps {
  label: string
  children: ReactElement[];
  isRequired?: boolean;
  defaultClose?: boolean;
}

const SubheadingAccordion = ({ label, children, isRequired = false, defaultClose = false }: SubheadingAccordionProps) => {
  const [open, isOpen] = useState(!defaultClose);

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplate: '32px / 32px 1fr 32px',
          gap: 2,
          alignItems: 'center',
          padding: '4px',
          pl: 1,
          borderRadius: '4px',
          border: '1px solid',
          borderColor: 'level3'
        }}
      >
        <ManageSearchSharp sx={{ justifySelf: 'center' }} />
        <Typography>
          <strong>{label}</strong>
          {isRequired && <span style={{ fontStyle: 'italic' }}>&nbsp;(Required)</span>}
        </Typography>
        <ODAvatar variant="transparent" size="small" onClick={() => isOpen(!open)}>
          {open ? <ExpandLessSharp /> : <ExpandMoreSharp />}
        </ODAvatar>
      </Box>
      {open && children}
    </>
  );
};

interface SearchDialogProps {
  subject: 'load' | 'driver';
  currentRow: DispatchingDriver | DispatchingLoad;
  currentCard: ReactElement;
  internalSearchTable: ReactElement;
  onClose: () => void;
  defaultSearch: SourceSearch | null;
  openSimRate?: () => void;
  setRefreshDashboard: (_tf: boolean) => void;
  setShowSuccessSnackBar?: ((_tf: boolean) => void) | undefined;
  driverEvents?: DriverEvent[] | [];
}

const SearchDialog = ({ subject, currentRow, currentCard, internalSearchTable, defaultSearch, openSimRate, onClose, setRefreshDashboard, setShowSuccessSnackBar, driverEvents }: SearchDialogProps) => {
  const specifics = SUBJECT_SPECIFICS[subject];
  const { setSessionUid } = useContext(SessionContext);
  const { showSourceSuggestions, noExternalLoadsLimit, filterMinRatePerMi, persistSourceSearchEquipmentType, showNextEventModal } = useFlags();

  const initPickUpCity = useMemo(() => {
    if (!!defaultSearch) return { city_state: defaultSearch?.pickup_city_state, timezone: defaultSearch.pickup_tz };
    const driver = currentRow as DispatchingDriver;
    return driver?.avail_city ? { city_state: `${driver.avail_city}, ${driver.avail_state}`, timezone: currentRow?.avail_timezone } : null;
  }, []);
  const initPickUpStart = useMemo(() => {
    if (!!defaultSearch) return moment.tz(defaultSearch?.pickup, DATE_TIME_WITH_SEC_SPACE_DIV, DEFAULT_TIMEZONE);
    if (subject === LOAD) {
      const driver = currentRow as DispatchingDriver;
      if (!driver?.avail_datetime) return null;
      return moment.tz(driver?.avail_datetime, AMERICAN_DATE_TIME, DEFAULT_TIMEZONE);
    }
    const load = currentRow as DispatchingLoad;
    if (!load?.pickup_end_et) return null;
    return moment.tz(load?.pickup_end_et, SHORT_DATE_TIME, DEFAULT_TIMEZONE);
  }, []);
  const initPickUpEnd = useMemo(() => !initPickUpStart ? null : initPickUpStart.clone().add(!!defaultSearch ? 24 : 12, 'h'), []);
  const initDropOffCity = useMemo(() => {
    if (!!defaultSearch && !!defaultSearch?.dropoff_city_state) {
      return {
        city_state: defaultSearch?.dropoff_city_state,
        timezone: !!defaultSearch ? defaultSearch.dropoff_tz : undefined
      };
    }
    return null;
  }, []);
  const initDropOffEnd = useMemo(() => {
    if (!!defaultSearch && !!defaultSearch?.dropoff) return moment.tz(defaultSearch?.dropoff, DATE_TIME_WITH_SEC_SPACE_DIV, DEFAULT_TIMEZONE).add(24, 'h');
    return null;
  }, []);
  const initDropOffStart = useMemo(() => {
    if (!!defaultSearch && !!initPickUpStart && !!initDropOffEnd) {
      const diff = initPickUpStart.diff(initDropOffEnd, 'h', true);
      if (Math.abs(diff) < 48) return initPickUpStart;
      return initDropOffEnd.clone().subtract(48, 'h');
    }
    return null;
  }, []);

  const { resetValues, setSearch, loading, hasResults, setHasResults, count, page, rowsPerPage, setPage, setRowsPerPage } = useContext(SearchContext);
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [searchMode, setSearchMode] = useState(!defaultSearch ? INTERNAL : EXTERNAL);
  const [queryType, setQueryType] = useState(BY_PARAMS);
  const [showToggle, setShowToggle] = useState(false);
  const [adjustmentOpen, isAdjustmentOpen] = useState(true);
  const [id, setId] = useState('');
  const [pickUpCity, setPickUpCity] = useState<CityState | null>(initPickUpCity);
  const [pickUpStart, setPickUpStart] = useState<Moment | null>(initPickUpStart);
  const [pickUpEnd, setPickUpEnd] = useState<Moment | null>(initPickUpEnd);
  const [pickUpRadius, setPickUpRadius] = useState(DEFAULT_RADIUS);
  const [dropOffCity, setDropOffCity] = useState<CityState | null>(initDropOffCity);
  const [dropOffStart, setDropOffStart] = useState<Moment | null>(initDropOffStart);
  const [dropOffEnd, setDropOffEnd] = useState<Moment | null>(initDropOffEnd);
  const [dropOffRadius, setDropOffRadius] = useState(DEFAULT_RADIUS);
  const [equipmentTypes, setEquipmentTypes] = useState((persistSourceSearchEquipmentType && !!defaultSearch?.equipment_types) ? defaultSearch?.equipment_types : [] as string[]);
  const [ageInDays, setAgeInDays] = useState<number | null>(null);
  const [weight, setWeight] = useState<number | null>(null);
  const [minRatePerMile, setMinRatePerMile] = useState<number | null>(null);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [debouncedIdSearch] = useState(() => (debounce((search) => setSearch(search), 300)));
  const userSettings = useSelector((state: RootState) => state.userSettings);
  const dispatch = useDispatch();

  const validateInputs = () => {
    if (queryType === BY_PARAMS) {
      const maxRadius = noExternalLoadsLimit ? MIN_MAX_RADIUS : MAXIMUM_RADIUS;
      if (!pickUpStart) return false;
      if (subject === LOAD) {
        if (!(pickUpCity && pickUpEnd)) return false;
        if ((pickUpEnd && !pickUpStart) || (dropOffEnd && !dropOffStart)) return false;
        if (pickUpStart?.isAfter(dropOffStart) || dropOffStart?.isBefore(pickUpStart)) return false;

        if (searchMode === EXTERNAL) {
          if (weight !== null && weight < 0) return false;
          if (ageInDays !== null && ageInDays < 0) return false;
          if (minRatePerMile !== null && minRatePerMile < 0) return false;
        }
      }
      if (isRadiusInvalid(pickUpRadius, maxRadius) || isRadiusInvalid(dropOffRadius, maxRadius)) return false;
    } else if (!id) return false;
    return true;
  };

  useEffect(() => {
    setSearchEnabled(validateInputs());
  }, [pickUpCity, pickUpStart, pickUpEnd, pickUpRadius, dropOffCity, dropOffStart, dropOffEnd, dropOffRadius, id, ageInDays, weight, minRatePerMile, equipmentTypes]);

  const onReset = () => {
    setPickUpCity(initPickUpCity);
    setPickUpStart(initPickUpStart);
    setPickUpEnd(initPickUpEnd);
    setPickUpRadius(DEFAULT_RADIUS);
    setDropOffCity(initDropOffCity);
    setDropOffStart(initDropOffStart);
    setDropOffEnd(initDropOffEnd);
    setDropOffRadius(DEFAULT_RADIUS);
    setEquipmentTypes([]);
    setAgeInDays(null);
    setWeight(null);
    setMinRatePerMile(null);
    setId('');
  };

  const handleSearch = (queryTypeOverride = '') => {
    const search = {
      ...(subject === DRIVER ? {} : {
        driverId: currentRow?.driver?.driver_id,
        driverPk: currentRow?.driver?.id,
        engineRunId: (currentRow as DispatchingLoad)?.engine_run,
        movementId: defaultSearch?.movement_id,
        submoveId: defaultSearch?.submove_id,
      }),
      params: {
        pickUpCity: pickUpCity?.city_state,
        pickUpRadius,
        pickUpStart,
        pickUpEnd,
        dropOffCity: dropOffCity?.city_state,
        dropOffRadius,
        dropOffStart,
        dropOffEnd,
        equipmentTypes,
        ageInDays,
        weight,
        minRatePerMile,
      }
    };

    if ((queryTypeOverride || queryType) === BY_ID) debouncedIdSearch({ ...search, keyword: id });
    else setSearch(search);

    setSearchEnabled(false);
    setHasResults(false);
  };

  useEffect(() => {
    if (validateInputs()) handleSearch();
    setSessionUid(uuidv4());
  }, []);

  useEffect(() => {
    handleSearch();
  }, [id]);

  const handleQueryTypeChange = (newType: string) => {
    setQueryType(newType);
    setHasResults(false);
    if (validateInputs()) handleSearch(newType);
  };

  const handleSearchModeChange = (newMode: string) => {
    setSearchMode(newMode);
    if (newMode === EXTERNAL) setQueryType(BY_PARAMS);
  };

  const handleClose = () => {
    resetValues();
    onClose();
    setRefreshDashboard(true);
  };

  const searchPanelWidth = searchPanelOpen ? '471px' : '56px';

  let simRatePerMi = 0;
  if (!!defaultSearch) {
    simRatePerMi = parseFloat(defaultSearch?.simulated_rate_per_mile);
  }

  const idPrefix = `${subject}-search-dialog`;

  return (
    <Dialog
      id={idPrefix}
      open={true}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'colors.lightBlue0',
          minHeight: 'calc(100vh - 32px)',
          minWidth: 'calc(100vw - 32px)',
          margin: '16px',
          boxShadow: 'none',
        },
        '&.MuiDialog-root': { zIndex: 1300 },
        '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, .25)' }
      }}
    >
      <Box onMouseEnter={() => setShowToggle(true)} onMouseLeave={() => setShowToggle(false)}>
        <Drawer
          variant="permanent"
          open={searchPanelOpen}
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: 'colors.white',
              minHeight: 'unset',
              minWidth: 'unset',
              height: 'calc(100vh - 32px)',
              width: searchPanelWidth,
              borderRadius: '4px 0 0 4px',
              borderColor: 'level3',
              '&.MuiPickersPopper-paper': {
                height: 'unset',
                width: 'unset'
              }
            },
          }}
        >
          <Box
            sx={{
              pl: 1,
              py: 1,
              alignItems: 'center',
              width: 'calc(100% - 8px)',
              display: 'grid',
              gridTemplateRows: '40px',
              gridTemplateColumns: '40px 1fr auto 16px',
              columnGap: 2,
              borderBottom: 1,
              borderColor: 'level3',
              '> svg': { justifySelf: 'center' }
            }}
          >
            {specifics.searchIcon}
            {searchPanelOpen && <Typography><strong>{specifics.modalTitle}</strong></Typography>}
            <Link id="clear-all" component="button" variant="secondary" sx={{ whiteSpace: 'nowrap' }} onClick={onReset}>
              Reset All
            </Link>
            {showToggle && (
              <ToggleButton open={searchPanelOpen} onClick={() => setSearchPanelOpen(!searchPanelOpen)}>
                {searchPanelOpen ? <KeyboardArrowLeftSharp sx={{ color: 'text.primary' }} /> : <KeyboardArrowRightSharp sx={{ color: 'text.primary' }} />}
              </ToggleButton>
            )}
          </Box>
          {searchPanelOpen && (
            <>
              <Box id="search-info-box" sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2, '.source-soonness, #source-status-edit-menu': { display: 'none' } }}>
                {currentCard}
                {(!!defaultSearch && subject === LOAD) && (
                  <SourceLoadDetailCard
                    driver={currentRow as DispatchingDriver}
                    searchSuggestion={defaultSearch}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplate: '32px / 32px 1fr 32px',
                  gap: 2,
                  alignItems: 'center',
                  padding: '12px 16px',
                  borderWidth: '1px 0',
                  borderStyle: 'solid',
                  borderColor: 'level3',
                }}
              >
                <TuneSharp sx={{ justifySelf: 'center' }} />
                <Typography><strong>{specifics.adjustHeading}</strong></Typography>
                <ODAvatar variant="transparent" size="small" onClick={() => isAdjustmentOpen(!adjustmentOpen)}>
                  {adjustmentOpen ? <RemoveSharp /> : <AddSharp />}
                </ODAvatar>
              </Box>
              <Box
                sx={{
                  p: 2,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 1 auto',
                  overflow: 'auto',
                  gap: 2,
                }}
              >
                {adjustmentOpen && (
                  <>
                    {searchMode === INTERNAL && (
                      <>
                        <ODToggle
                          selected={queryType}
                          options={[
                            { value: BY_PARAMS, label: specifics.paramsSearchPrompt },
                            { value: BY_ID, label: specifics.idSearchPrompt }
                          ]}
                          onChange={(_e, v) => handleQueryTypeChange(v)}
                        />
                        {queryType === BY_ID && (
                          <Box>
                            <InputLabel>{specifics.idSearchPrompt}</InputLabel>
                            <ODInput
                              id={`${subject}-id-search`}
                              value={id}
                              onChange={(event: ChangeEvent<HTMLInputElement>) => setId(event.target.value)}
                              endAdornment={(
                                <InputAdornment position="end" sx={{ mr: 2, columnGap: 2, color: 'text.primary' }}>
                                  {!!id && (
                                    <ODCloseButton
                                      id="clear-id"
                                      variant="transparent"
                                      size="small"
                                      onClick={() => setId('')}
                                    />
                                  )}
                                  <SearchSharp />
                                </InputAdornment>
                              )}
                              disableUnderline={true}
                              sx={{
                                pr: 0,
                                '#clear-id': { display: 'none' },
                                '&:hover': { '#clear-id': { display: 'contents' } }
                              }}
                            />
                          </Box>
                        )}
                      </>
                    )}
                    {(queryType === BY_PARAMS && subject === LOAD) && (
                      <>
                        <SubheadingAccordion label="Pick Up Details" isRequired defaultClose={!!defaultSearch}>
                          <CitySearch
                            label="Enter Pick Up City"
                            selectedLocations={pickUpCity}
                            setSelectedLocations={setPickUpCity}
                          />
                          <RadiusInput
                            prompt="Enter Pick Up Distance Max (miles)"
                            id="origin"
                            value={pickUpRadius}
                            setValue={setPickUpRadius}
                          />
                          <DateTimeSpan
                            prompt="Set Available for Pick Up"
                            start={pickUpStart}
                            setStart={setPickUpStart}
                            end={pickUpEnd}
                            setEnd={setPickUpEnd}
                            timezone={pickUpCity?.timezone}
                            dateCrossError={!!pickUpStart ? pickUpStart?.isAfter(dropOffStart) : false}
                            horizontal
                          />
                        </SubheadingAccordion>
                        <SubheadingAccordion label="Drop Off Details" defaultClose={!!defaultSearch}>
                          {showNextEventModal && driverEvents && !isEmpty(driverEvents) && (
                          <>
                            <InputLabel sx={{ pb: 0, overflow: 'visible' }}>Next Scheduled Event</InputLabel>
                            <Box sx={{ borderRadius: '4px', border: '1px solid', borderColor: 'level3', p: 0, mb: 0 }}> 
                              <NextEvent key="next-event-box" event={driverEvents[0]} />
                            </Box>
                            <Divider sx={{ color: 'level3' }} />
                          </>
                          )}
                          <CitySearch
                            label="Enter Drop Off City"
                            selectedLocations={dropOffCity}
                            setSelectedLocations={setDropOffCity}
                          />
                          <RadiusInput
                            prompt="Enter Drop Off Distance Max (miles)"
                            id="destination"
                            value={dropOffRadius}
                            setValue={setDropOffRadius}
                          />
                          <DateTimeSpan
                            prompt="Set Available for Drop Off"
                            start={dropOffStart}
                            setStart={setDropOffStart}
                            end={dropOffEnd}
                            setEnd={setDropOffEnd}
                            timezone={dropOffCity?.timezone}
                            dateCrossError={!!dropOffStart ? dropOffStart?.isBefore(pickUpStart) : false}
                            horizontal
                          />
                        </SubheadingAccordion>
                        {searchMode === EXTERNAL && (
                          <SubheadingAccordion label="Equipment, Age, Weight & Rate">
                            <Box>
                              <ODSelect
                                id="equipment-types"
                                multiple
                                label="Select Equipment Types"
                                menuOptions={Object.entries(EQUIPMENT_TYPES).map(([value, label]) => ({ value, label }))}
                                value={equipmentTypes}
                                onChange={(event: SelectChangeEvent<unknown>) => {
                                  setEquipmentTypes(event.target.value as string[]);
                                  if (persistSourceSearchEquipmentType) {
                                    dispatch(UpdateUserSettings({ ...userSettings, externalSourceEquipmentTypes: event.target.value }));
                                  }
                                }}
                                sx={{ width: '100%' }}
                              />
                            </Box>
                            {equipmentTypes.length > 0 ? (
                              <SelectedOptions
                                keyBase="filter-reason"
                                // @ts-expect-error TS objects to using a string as an index key
                                value={equipmentTypes.map((r) => ({ equipment: r, label: EQUIPMENT_TYPES[r] }))}
                                itemKey="label"
                                label="Equipment Type"
                                removeOptionCB={({ equipment }: { equipment: string }) => {
                                  const newEquipmentTypes = [...equipmentTypes].filter((s) => s !== equipment);
                                  setEquipmentTypes(newEquipmentTypes);
                                  if (persistSourceSearchEquipmentType) {
                                    dispatch(UpdateUserSettings({ ...userSettings, externalSourceEquipmentTypes: newEquipmentTypes }));
                                  }
                                }}
                              />
                            ) : <></>}
                            <ODNumberInput
                              id="max-load-age"
                              prompt="Select Max Load Age (Days)"
                              value={ageInDays}
                              setValue={setAgeInDays}
                              increment={1}
                              errored={ageInDays !== null ? ageInDays < 0 : false}
                              errorMessage="Max Load Age needs a positive value"
                              placeholder="Max"
                            />
                            <ODNumberInput
                              id="max-weight"
                              prompt="Select Max Weight (Lbs)"
                              value={weight}
                              setValue={setWeight}
                              increment={5000}
                              errored={weight !== null ? weight < 0 : false}
                              errorMessage="Max Weight needs a positive value"
                              placeholder="Max"
                            />
                            {filterMinRatePerMi && (
                              <ODNumberInputDollars
                                id="min-rate-per-mile"
                                prompt="Select Min Rate Per Mile ($)"
                                value={minRatePerMile}
                                setValue={setMinRatePerMile}
                                increment={0.10}
                                errored={minRatePerMile !== null ? minRatePerMile < 0 : false}
                                errorMessage="Min Rate Per Mile needs a positive value"
                                placeholder="Min"
                              />
                            )}
                          </SubheadingAccordion>
                        )}
                      </>
                    )}
                    {(queryType === BY_PARAMS && subject === DRIVER) && (
                      <>
                        <div>
                          <InputLabel>Set Available for Dispatch Before</InputLabel>
                          <ODDateTimePicker
                            inputId=""
                            currentValue={pickUpStart}
                            changeValue={setPickUpStart}
                            variant={FORM_VARIANT}
                            // @ts-expect-error subject being confirmed means this will have an avail_tz
                            timezone={currentRow?.pickup_timezone}
                          />
                        </div>
                        <RadiusInput
                          prompt="Enter Distance From Pick Up Radius (miles)"
                          id="origin"
                          value={pickUpRadius}
                          setValue={setPickUpRadius}
                        />
                      </>
                    )}
                  </>
                )}
              </Box>

              {queryType === BY_PARAMS && (
                <Box sx={{ p: '8px 16px', borderTop: 1, borderTopColor: 'level3' }}>
                  <ODButton
                    id={`search-${subject}s-${subject === LOAD ? `${searchMode}-` : ''}${queryType}`}
                    disabled={!searchEnabled}
                    variant="slideoutBlue"
                    onClick={() => { if (searchEnabled) handleSearch(); }}
                  >
                    Search
                  </ODButton>
                </Box>
              )}
            </>
          )}
        </Drawer>
      </Box>
      <Box
        sx={{
          width: `calc(100vw - 32px - ${searchPanelWidth})`,
          height: 'calc(100vh - 32px)',
          marginLeft: searchPanelWidth,
          position: 'absolute',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            height: 56,
            borderBottom: 1,
            borderBottomColor: 'level3',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            px: 2,
            justifyContent: 'space-between'
          }}
        >
          {(showSourceSuggestions && subject === LOAD) ? (
            <ODTabs
              value={searchMode}
              setValue={(_e, v) => handleSearchModeChange(v)}
              tabOptions={[
                { value: INTERNAL, label: 'Internal Loads', icon: specifics.resultsIcon },
                { value: EXTERNAL, label: 'External Sources', icon: <SearchSharp />, isBeta: true }
              ]}
            />
          ) : (
            <Box
              sx={{
                height: 40,
                display: 'grid',
                gridTemplateColumns: '40px auto',
                alignItems: 'center',
                gap: 2,
                ml: -1,
                '> svg': { justifySelf: 'center' }
              }}
            >
              {specifics.resultsIcon}
              <Typography><strong>{specifics.resultsTitle}</strong></Typography>
            </Box>
          )}
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {simRatePerMi > 0 && (
              <>
                <Box
                  sx={{
                    height: '18px',
                    width: '18px',
                    backgroundColor: 'text.secondary',
                    borderRadius: '9px',
                    display: 'flex',
                    alignItems: 'center',
                    ':hover': {
                      backgroundColor: 'colors.neutral5',
                      cursor: 'pointer'
                    }
                  }}
                  onClick={openSimRate}
                >
                  <AttachMoneySharp sx={{ color: 'text.inverse', ml: '.5px' }} />
                </Box>
                <Typography sx={{ color: 'text.secondary' }}>
                  {`Simulated Rate / Mile: ${centsAbbreviate(simRatePerMi)}`}
                </Typography>
              </>
            )}
            <ODCloseButton onClick={handleClose} />
          </Box>
        </Box>
        <Box sx={{ m: 2, mb: 0, height: `calc(100% - ${searchMode === INTERNAL ? '130px' : '89px'})` }}>
          <Box sx={{ display: loading ? 'none' : 'contents' }}>
            {searchMode === INTERNAL && internalSearchTable}
            {searchMode === EXTERNAL && (
              <LoadsSearchExternal
                lastLocationZip={defaultSearch?.last_location_zip}
                currentDriver={currentRow as DispatchingDriver}
                handleModalClose={handleClose}
                setShowSuccessSnackBar={setShowSuccessSnackBar}
              />
            )}
          </Box>
          {(loading || !hasResults) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                justifyContent: 'center',
                width: '100%',
                height: loading ? '100%' : '90%',
                color: loading ? 'text.primary' : 'text.secondary'
              }}
            >
              {loading && <ODLoaderLabeled loadingText={specifics.loading} color="optimalblue.main" />}
              {(!loading && !hasResults) && (
                <>
                  <InfoOutlined />
                  <Typography>{`No results were returned for this search. Kindly broaden your search to locate available ${subject}s.`}</Typography>
                </>
              )}
            </Box>
          )}
        </Box>
        {searchMode === INTERNAL && (
          <ODTablePagination
            sx={{ backgroundColor: 'level1', borderTop: 1, borderColor: 'level3', minHeight: '56px' }}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage: number) => setPage(newPage)}
            onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
            selectId={`${idPrefix}-page-select`}
            // @ts-expect-error breaking change with slotProps
            slotProps={{
              actions: {
                nextButton: { id: `${idPrefix}-page-next` },
                previousButton: { id: `${idPrefix}-page-previous` }
              }
            }}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default SearchDialog;
