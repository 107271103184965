import { combineReducers } from 'redux';
import loads from './loads';
import auth from './auth';
import notificationReducer from './notificationReducer';
import contractReducer from './contractsReducer';
import tactical from './tacticalProcurementReducer';
import general from './generalReducer';
import dispatchingReducer from './dispatchingReducer';
import userSettings from './userSettingsReducer';
import forecasting from './forecasting';
import trailerAssignmentReducer from './trailerAssignmentReducer';
import configStore from './configStoreReducer';

const rootReducer = combineReducers({
  auth,
  loads,
  notificationReducer,
  contractReducer,
  tactical,
  dispatchingReducer,
  general,
  userSettings,
  forecasting,
  trailerAssignmentReducer,
  configStore
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
