/* eslint-disable @typescript-eslint/no-shadow */
import React, { Dispatch, SetStateAction } from 'react';
import moment from 'moment-timezone';
import { Box, Link, Typography } from '@mui/material';
import OpenInNewSharp from '@mui/icons-material/OpenInNewSharp';
import MailOutlineSharp from '@mui/icons-material/MailOutlineSharp';
import { CopyClick } from '../../../common/CopyClick';
import { getLocalizedIsoTimeRange, SHORT_DATE_TIME_TZ } from '../../../utils/datetimes';

import { formatWithUnit } from '../../../utils/math';

import { LOAD_ID, PICKUP_RADIUS, DROPOFF_PREFIX, DROPOFF_RADIUS, WEIGHT, LENGTH, RATE_PER_MILES, DEADHEAD, AGE_IN_MINUTES, SOURCE_ACTION, PICKUP_PREFIX, LOADED_MILES, RATE_PER_TOTAL_MILE, HOS_ACTION, } from '../helpers/constants';

import { ExternalLoad } from './types';

const divmod = (dividend: number, divisor: number) => [Math.floor(dividend / divisor), dividend % divisor];

export const formatData = (load: ExternalLoad, field: string, setEmailUrl: Dispatch<SetStateAction<string | null | undefined>>, setCurrOpenHOSBreakdown?: any) => {
  // @ts-expect-error TS objects to using a string as an index key
  const value = load[field];
  // If the source is an email, the book_link is expected to be null
  if ((value === null || value === '') && ![SOURCE_ACTION].includes(field)) return '-';

  switch (field) {
    case PICKUP_PREFIX:
    case DROPOFF_PREFIX:
      // @ts-expect-error TS objects to using a string as an index key
      const { [`${field}_start`]: start, [`${field}_end`]: end, [`${field}_timezone`]: timezone } = load;
      if (!!start) {
        if (!end) return moment(start).tz(timezone)?.format(SHORT_DATE_TIME_TZ);
        return getLocalizedIsoTimeRange(start, end, timezone);
      } return '-';
    case SOURCE_ACTION:
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', svg: { color: 'optimalblue.main' } }}>
          {load.is_email ? (
            <>
              <MailOutlineSharp />
              <Link
                className="source-email"
                variant="blue"
                component="button"
                onClick={() => setEmailUrl(load.email_uri)}
              >
                Email Details
              </Link>
            </>
          ) : (
            <>
              <OpenInNewSharp />
              <Link
                className="source-link"
                variant="blue"
                href={value}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                {load?.source}
              </Link>
            </>
          )}
        </Box>
      );
    case HOS_ACTION:
      return (
        <>
          <Link
            variant="blue"
            className="hos-detail-link"
            onClick={(e) => {
              e.stopPropagation();
              setCurrOpenHOSBreakdown(load);
            }}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            View Details
          </Link>
        </>
      );
    case LOAD_ID: 
      return (
        <CopyClick copyText={value}>
          <Typography maxWidth={90} whiteSpace="nowrap" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {value}
          </Typography>
        </CopyClick>
      );
    case LENGTH: 
      return value.length ? formatWithUnit(value, 'ft') : '-';
    case WEIGHT: 
      return formatWithUnit(value, 'lbs');
    case DROPOFF_RADIUS:
    case PICKUP_RADIUS:
    case LOADED_MILES:
    case DEADHEAD:
      return formatWithUnit(Math.round(value), 'mi');
    case RATE_PER_MILES:
    case RATE_PER_TOTAL_MILE:
      return Intl.NumberFormat('en', { style: 'currency', currency: 'USD' })?.format(value);
    case AGE_IN_MINUTES:
      const [hours, minutes] = divmod(value, 60);
      const datum = `${minutes}m`;
      if (!hours) return datum;
      if (hours < 24) {
        return `${hours}h${datum}`;
      } 
      return `${Math.floor(hours / 24)}d`;
      
    default:
      if (Array.isArray(value) && !value.length) return '-';
      if (Array.isArray(value) && value.length > 0) return value.join(', ');
      return value;
  }
};
