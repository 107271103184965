import React from 'react';
import { kebabCase } from 'lodash';
import { Moment } from 'moment-timezone';
import { InputLabel, Box, Typography } from '@mui/material';
import { ODDateTimePicker, FORM_VARIANT } from '../../../common/ODDatePicker';
import { getTimeZoneAcronym } from '../../../utils/datetimes';

interface DateTimeSpanProps {
  prompt: string;
  start: Moment | null;
  setStart: (_datetime: Moment) => void;
  end: Moment | null;
  setEnd: (_datetime: Moment) => void;
  timezone?: string;
  dateCrossError: boolean;
  horizontal: boolean;
}

const DateTimeSpan = ({ prompt, start, setStart, end, setEnd, timezone, dateCrossError, horizontal }: DateTimeSpanProps) => {
  const needsStart = !!end && !start;
  const needsEnd = !!start && !end;
  const startEndCross = start?.isAfter(end);
  let startTimeZone = '';
  let endTimeZone = '';
  if (timezone) {
    if (!!start) startTimeZone = `(${getTimeZoneAcronym(start, timezone)})`;
    if (!!end) endTimeZone = `(${getTimeZoneAcronym(end, timezone)})`;
  }

  return (
    <Box
      sx={{
        '.MuiFormControl-root': { height: '40px' },
        display: 'grid',
        gap: 2,
        ...(horizontal && { gridTemplateColumns: '1fr 1fr' })
      }}
    >
      <Box>
        <InputLabel>{`${prompt} Start ${startTimeZone}`}</InputLabel>
        <ODDateTimePicker
          inputId={`${kebabCase(prompt)}-start`}
          currentValue={start}
          changeValue={setStart}
          variant={FORM_VARIANT}
          timezone={timezone}
          isErrored={needsStart || startEndCross || dateCrossError}
        />
        <Typography sx={{ fontSize: '12px', color: 'alert.main' }}>
          {needsStart && 'Start date required'}
          {startEndCross && 'End date must be after the Start date'}
          {dateCrossError && 'Drop Off date must be after the Pick Up date'}
        </Typography>
      </Box>
      <Box>
        <InputLabel>{`${prompt} End ${endTimeZone}`}</InputLabel>
        <ODDateTimePicker
          inputId={`${kebabCase(prompt)}-end`}
          currentValue={end}
          changeValue={setEnd}
          variant={FORM_VARIANT}
          timezone={timezone}
          isErrored={needsEnd || startEndCross}
        />
        {needsEnd && <Typography sx={{ fontSize: '12px', color: 'alert.main' }}>End time required</Typography>}
      </Box>
    </Box>
  );
};

export default DateTimeSpan;
