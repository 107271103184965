import { SET_CONFIGS } from '../constants/actionTypes';

const initialState = {
  dispatching: {},
  general: {
    ui: {
      menu: {
        show: {
          loadAcceptance: false,
          tp: false,
          commitments: false,
          dispatching: true,
          loadDemandForecast: false,
          trailerAssignments: false,
        }
      }
    }
  },
  loadAcceptance: {},
  vfa: {}
};

const setConfigs = (state: typeof initialState, { configs }: { configs : Record<string, string | boolean | number> }) => (
  {
    ...state,
    ...configs
  }
);

const configStoreReducer = (state = initialState, action: { type: string, configs: Record<string, string | boolean | number> }) => {
  if (!action) return state;
  switch (action.type) {
    case SET_CONFIGS:
      return setConfigs(state, action);
    default:
      return state;
  }
};

export default configStoreReducer;
